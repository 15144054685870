import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
// import Autocomplete from "./autocomplete"; // Make sure to adjust the import path if needed
import Notification from "../../../components/notification/notification";


const Add_sales_invoice = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [addSalesInvoice, SetAddSalesInvoice] = useState({
    customer: "",
    sales_date: "",
    sales_time: "",
    payment_due_date: "",
    grand_total: "",
    payment_method: "",
    paid_from_account_id: "",
    paid_to_account_id: "",
    prepared_by: "",
    sales_invoice_items: {
      data: [
        { item_name: "", quantity: 0, rate: 0, amount: 0 },
        { item_name: "", quantity: 0, rate: 0, amount: 0 },
      ],
    },
    description: "",
    remarks: "",
    salesInvoiceFile: "",
  });

  const [sampleDetails, setSampleDetails] = useState({ qualityStages: [] });
  const [allCustomers, setAllCustomers] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [sampleIds, setSampleIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handle input change
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;

    if (index !== null) {
      const newStages = [...sampleDetails.qualityStages];
      newStages[index][name] = value;
      setSampleDetails((prevDetails) => ({
        ...prevDetails,
        qualityStages: newStages,
      }));
    } else {
      setSampleDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  // Fetch Last sample_testing_id based on sample_type
  const fetchLastTestSampleID = async () => {
    try {
      const { sample_type } = sampleDetails; // Destructure sample_type from state

      // Send sample_type as a query parameter
      const response = await axios.get(
        `${backendUrl}/qualitySampleTest/getLastSampleTestID`,
        {
          params: { sample_type },
        }
      );

      if (response.data) {
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          sample_test_id: response.data.lastSampleId, // Adjust this based on the structure of your response
        }));
      } else {
        console.error("No data received for the last sample ID.");
      }
    } catch (error) {
      console.error("Error fetching last sample ID:", error);
    }
  };

  useEffect(() => {
    fetchLastTestSampleID();
  }, [sampleDetails.sample_type]);

  // Handle Save Form
  const handleSaveForm = async () => {
    // console.log(sampleDetails);
    // try {
    //   const response = await axios.post(
    //     `${backendUrl}/qualitySampleTest/addSampleTesting`,
    //     sampleDetails
    //   );
    //   setNotification({
    //     message: "Sample details saved successfully!",
    //     type: "success",
    //   });
    //   resetTable(true);
    // } catch (error) {
    //   console.error(error);
    //   setNotification({
    //     message: "Error saving sample details. Please try again.",
    //     type: "error",
    //   });
    // }
    // setIsSaveConfirmationOpen(false);
  };

  // Handle Reset Form
  const handleResetForm = () => {
    // setSampleDetails({
    //   sample_type: "",
    //   sample_id: "",
    //   received_date: "",
    //   sample_test_id: "",
    //   result: "",
    //   qualityStages: [],
    // });
    setIsResetConfirmationOpen(false);
  };

  const handleSampleIDSelect = async (selectedOption) => {
    const sampleID = selectedOption ? selectedOption.value : "";

    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      sample_id: sampleID,
    }));

    if (sampleID) {
      try {
        // Make GET request to fetch details based on the selected Sample ID
        const response = await axios.get(
          `${backendUrl}/qualitySamples/getSamples`,
          {
            params: { sample_id: sampleID },
          }
        );
        // Update the form fields with the fetched data
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          sample_type: response.data[0].sample_type,
        }));

        console.log(sampleDetails);
      } catch (error) {
        console.error("Error fetching sample details:", error);
      }
    }
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Open Reset Confirmation
  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  // Close Reset Confirmation
  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Open Save Confirmation
  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  // Close Save Confirmation
  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  // Add a new testing stage
  const addStage = () => {
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      qualityStages: [
        ...prevDetails.qualityStages,
        { testing_stage: "", percentage: "", remark: "" },
      ],
    }));
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Remove a testing stage
  const removeStage = (index) => {
    const newStages = sampleDetails.qualityStages.filter((_, i) => i !== index);
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      qualityStages: newStages,
    }));
  };

  const getAllCustomers = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyType`,
        {
          params: {
            party_type: "Customer",
          },
        }
      );

      console.log(response.data);

      setAllCustomers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Sales Invoice</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Customer */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Customer
                </label>
                <select
                  name=""
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  // value={sampleDetails.payment_method}
                  // onChange={handleInputChange}
                >
                  <option value=""></option>
                  {allCustomers && allCustomers.length > 0 ? (
                    allCustomers.map((type) => (
                      <option key={type.customer_id} value={type.customer_name}>
                        {type.customer_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Customer available</option>
                  )}
                </select>
              </div>

              {/* invoice id eka add karanna oneee state ekata */}
              {/* Invoice NO */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Invoice No
                </label>
                <input
                  type="text"
                  name="sample_test_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  // value={sampleDetails.sample_test_id}
                  // onChange={handleInputChange}
                />
              </div>

              {/* Invoice Date */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Invoice Date
                </label>
                <input
                  type="date"
                  name=""
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  // value={sampleDetails.received_date}
                  // onChange={handleInputChange}
                />
              </div>

              {/* Payment Due Date */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Due Date
                </label>
                <input
                  type="date"
                  name=""
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  // value={sampleDetails.received_date}
                  // onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <hr className="mt-10" />

          {/* Dynamically render testing stages */}
          {sampleDetails.qualityStages.map((stage, index) => (
            <div key={index} className="relative mb-4">
              <div className="flex items-center">
                <label className="block mb-2 mr-2 text-gray-700">
                  Items {index + 1}
                </label>
                <button
                  type="button"
                  className="absolute top-0 right-0 mt-2 mr-2 font-semibold text-red-600 hover:text-red-800"
                   onClick={() => removeStage(index)}
                >
                  <IoIosCloseCircleOutline />
                </button>
              </div>

              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700"> Item</label>
                  <input
                    type="text"
                    name="testing_stage"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    // value={stage.testing_stage}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">Quantity</label>
                  <input
                    type="text"
                    name="percentage"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    // value={stage.percentage}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">Rate</label>
                  <input
                    type="text"
                    name="remark"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    // value={stage.remark}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">Amount</label>
                  <input
                    type="text"
                    name="remark"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    // value={stage.remark}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addStage}
            className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Add Row
          </button>

          <hr className="mt-10" />

          <div className="flex justify-between">
            {/* Total Amount */}
            <div className="w-1/2 mr-2">
              <label className="block text-gray-700 font-semibold mb-1">
                Total Amount
              </label>
              <input
                type="text"
                name="result"
                className="w-full p-2 mt-2 border border-gray-300 rounded"
                // value={sampleDetails.result}
                // onChange={handleInputChange}
              />
            </div>

            {/* Payment method dropdown */}
            <div className="w-1/2 ml-2">
              <label className="block text-gray-700 font-semibold mb-1">
                Payment Method
              </label>
              <select
                name="payment_method"
                className="w-full p-2 mt-2 border border-gray-300 rounded"
                // value={sampleDetails.payment_method}
                // onChange={handleInputChange}
              >
                <option value=""></option>
                <option value="Cash">Cash</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
          </div>

          <hr className="mt-10" />

          <div className="flex justify-between">
            {/* Account Paid To */}
            <div className="w-1/2 ml-2">
              <label className="block text-gray-700 font-semibold mb-1">
                Account Paid To
              </label>
              <select
                name="Select Account"
                className="w-full p-2 mt-2 border border-gray-300 rounded"
                // value={sampleDetails.payment_method}
                // onChange={handleInputChange}
              >
                <option value=""></option>
                <option value="Cash">Cash</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
          </div>

          <hr className="mt-10" />

          {uploadedFiles.length === 0 && (
            <>
              {/* Section for Document Upload */}
              <h1 className="text-2xl font-bold mt-10 mb-4">
                Sales Invoice Document
              </h1>
              <button
                type="button"
                onClick={handleOpenModal}
                className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
              >
                <div className="flex items-center gap-2">
                  <span className="text-sm font-bold">
                    Click Here to Upload
                  </span>
                  <IoIosCloudUpload className="text-xl" />
                </div>
              </button>
            </>
          )}

          {/* Display Uploaded Files */}
          {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-8">
          <button
            type="button"
            onClick={handleOpenSaveConfirmation}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Save
          </button>

          <button
            type="button"
            onClick={handleOpenResetConfirmation}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          >
            Reset
          </button>
        </div>
      </div>

      {/* Confirmation Modals */}
      {isResetConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">
              Are you sure you want to reset the form?
            </h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseResetConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleResetForm}
                className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">
              Are you sure you want to save the form?
            </h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseSaveConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSaveForm}
                className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Add_sales_invoice;
