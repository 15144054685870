/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Upload_Invoice_Purchase_Order from "./uploadInvoice";
import DialogBoxInvoiceDocView from "./invoiceView";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

const Purchase_Order = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [showDialogBox, setshowDialogBox] = useState(false);
  const [showviewDialogBox, setshowviewDialogBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tenders, setTenders] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [tenderId, setTenderId] = useState("");
  const [tenderName, setTenderName] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const openDIalog = () => {
    setshowDialogBox(true);
  };

  const closeDialog = () => {
    setshowDialogBox(false);
  };

  const openViewDialog = () => {
    setshowviewDialogBox(true);
  };

  const closeViewDialog = () => {
    setshowviewDialogBox(false);
  };

  const handleCreatePurchaseOrder = () => {
    navigate("/purchaseOrder/create", { state: selectedTender });
  };

  const handleViewPurchaseOrder = () => {
    navigate("/purchaseOrder/view", { state: selectedTender });
  };

  const handleCreateVoucher = () => {
    navigate("/voucher/create", { state: selectedTender });
  };

  const handleViewVoucher = () => {
    navigate("/voucher/view", { state: selectedTender });
  };

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/search/tender/${query}`
        );
        setTenders(response.data);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
  };
  return (
    <div className="bg-background overflow-y-auto h-screen font-poppins">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Purchase Order
      </p>

      {/* Search Box */}
      <div className="ml-6">
        <form class="form relative">
          <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              class="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button
            type="button"
            className="btn btn-primary text-[#797C80] hover:bg-gray-100 ml-2"
            onClick={handleReset}
          >
            RESET
          </button>
          <div className="mt-4 relative">
            {isDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                {tenders.length > 0 ? (
                  tenders.map((result) => (
                    <li
                      key={result._id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      value={selectedTender}
                      onClick={() => handleOptionClick(result)}
                    >
                      {result.tender_id}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No results found</li>
                )}
              </ul>
            )}
          </div>
        </form>
      </div>

      <div className="bg-white w-[95%] h-[10%] rounded-[36px] ml-6 mt-4">
        <div className=" grid grid-cols-2 gap-4 ml-[8%]">
          <div className=" mt-[2%]">
            <label className=" text-[22px] font-bold">Tender ID:</label>
            <input
              className="bg-[#F5F5F5] w-[50%] rounded-[36px] ml-6 h-[46px]"
              value={tenderId}
              onChange={(e) => setTenderId(e.target.value)}
            ></input>
          </div>
          <div className="mt-[2%]">
            <label className=" text-[22px] font-bold">Tender Name:</label>
            <input
              className="bg-[#F5F5F5] w-[50%] rounded-[36px] ml-6 h-[46px]"
              value={tenderName}
              onChange={setTenderName}
            ></input>
          </div>
        </div>
      </div>

      <div className="bg-white w-[95%] h-[20%] rounded-[36px] ml-6 mt-4">
        <p className="font-bold text-[33px] ml-[2%] mt-[1%]">Check List</p>
        <div className="flex justify-center">
          <div className="grid grid-cols-3 gap-16">
            <div className="bg-[#F5F5F5] rounded-[14px] h-[71px] w-[329px] flex justify-around items-center">
              <input
                checked
                id="green-checkbox"
                type="checkbox"
                className="w-[54px] h-[54px] rounded accent-green-500"
              />
              <label
                for="green-checkbox"
                class="ms-2 text-sm font-medium text-[22px]"
              >
                Purchase Order
              </label>
            </div>
            <div className="bg-[#F5F5F5] rounded-[14px] h-[71px] w-[329px] flex justify-around items-center">
              <input
                checked
                id="green-checkbox"
                type="checkbox"
                className="w-[54px] h-[54px] rounded accent-green-500"
              />
              <label
                for="green-checkbox"
                class="ms-2 text-sm font-medium text-[22px]"
              >
                Invoice
              </label>
            </div>
            <div className="bg-[#F5F5F5] rounded-[14px] h-[71px] w-[329px] flex justify-around items-center">
              <input
                checked
                id="green-checkbox"
                type="checkbox"
                className="w-[54px] h-[54px] rounded accent-green-500"
              />
              <label
                for="green-checkbox"
                class="ms-2 text-sm font-medium text-[22px]"
              >
                GRN
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 card grid grid-cols-3 gap-10 ml-6 w-[95%]">
        {hasPermission(1910) && (
          <div className="flex  bg-[#F3F8FF] rounded-[22px] justify-around ">
            <div>
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Purchase
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                Order
              </h3>
            </div>
            <div className="mt-[2%]">
              <div>
                {hasPermission(2700) && (
                  <button
                    className=" bg-[#797C80] text-[22px] text-white font-bold rounded-[20px] w-[100px]"
                    onClick={handleViewPurchaseOrder}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Card 2 */}
        {hasPermission(1920) && (
          <div className="flex  bg-[#F3F8FF] rounded-[22px] justify-around">
            <div>
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Invoice
              </h2>
            </div>
            <div className="mt-[2%]">
              <div>
                {hasPermission(2710) && (
                  <button
                    className=" bg-[#797C80] text-[22px] text-white font-bold rounded-[20px] w-[100px]"
                    onClick={openViewDialog}
                  >
                    View
                  </button>
                )}
              </div>
              {showviewDialogBox && (
                <DialogBoxInvoiceDocView
                  onClose={closeViewDialog}
                  data={selectedTender.tender_id}
                />
              )}
              <div>
                {hasPermission(2711) && (
                  <button
                    className=" bg-[#9CAC8B] text-[22px] text-white font-bold rounded-[20px] w-[100px] mt-2"
                    onClick={openDIalog}
                  >
                    Upload
                  </button>
                )}
              </div>
              {showDialogBox && (
                <Upload_Invoice_Purchase_Order
                  onClose={closeDialog}
                  data={selectedTender.tender_id}
                />
              )}
            </div>
          </div>
        )}

        {/* Card 3 */}
        {hasPermission(1930) && (
          <div className="flex  bg-[#F3F8FF] rounded-[22px] justify-around">
            <div>
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Received
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                GRN
              </h3>
            </div>
            <div className="mt-[10%]">
              <div>
                {hasPermission(2720) && (
                  <button className=" bg-[#9CAC8B] text-[22px] text-white font-bold rounded-[20px] w-[100px] mt-2">
                    View
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Card 2 */}
        {hasPermission(1940) && (
          <div className="flex  bg-[#F3F8FF] rounded-[22px] justify-around">
            <div>
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Voucher
              </h2>
            </div>
            <div className="mt-[2%]">
              <div>
                {hasPermission(2730) && (
                  <button
                    className=" bg-[#797C80] text-[22px] text-white font-bold rounded-[20px] w-[100px]"
                    onClick={handleViewVoucher}
                  >
                    View
                  </button>
                )}
              </div>
              <div>
                {hasPermission(2731) && (
                  <button
                    className=" bg-[#9CAC8B] text-[22px] text-white font-bold rounded-[20px] w-[100px] mt-2 mb-5"
                    onClick={handleCreateVoucher}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Purchase_Order;
