/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../components/permissions/permission";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import ConfirmationModal from "./confirmation_modal";

const View_Item_Category = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedItemCategory, setSelectedItemCategory] =
    useState("Item Category Type");
  const [subItemType, setSubItemType] = useState("");

  // Define a function to fetch data
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();
      setItemCategoryData(data);
      console.log("stores:", itemCategoryData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]);

  const handleDownload = (filePath) => {
    if (!filePath) {
      alert("No file available for download.");
      return;
    }

    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };

  const HandleOnClick = (item) => {
    // Navigate to the new page and pass the object
    navigate("/itemcategory/update", {
      state: { selectedRecord: item },
    });
  };

  const filteredStoreData = itemCategoryData.filter((itemsCategory) => {
    const matchesType =
      selectedItemCategory === "Item Category Type" ||
      itemsCategory.sub_category_id === selectedItemCategory; // Filter by sub_category_id

    const matchesSubType =
      subItemType === "" ||
      itemsCategory.item_sub_category_name
        .toLowerCase()
        .includes(subItemType.toLowerCase());

    return matchesType && matchesSubType;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredStoreData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (itemToDelete) {
      try {
        // Construct the URL with sub_category_id directly in the path
        const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/delete/${encodeURIComponent(
          itemToDelete.sub_category_id
        )}`;

        const response = await fetch(endpoint, {
          method: "DELETE",
        });

        if (response.ok) {
          console.log("Deleted item Category:", itemToDelete);
          setShowConfirmationModal(false);
          setItemToDelete(null);
          // Refresh the roles list after deletion
          fetchData();
        } else {
          console.error("Failed to delete item");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setItemToDelete(null);
  };
  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          View Item Category
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="mt-3">
                <div className="flex flex-col">
                  <label className="text-xl mb-2 font-bold text-[#797C80] font-sans"></label>
                  <select
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    value={selectedItemCategory}
                    onChange={(e) => setSelectedItemCategory(e.target.value)}
                  >
                    <option className="font-sans-bold">
                      Item Category Type
                    </option>
                    {itemCategoryData.map((items, index) => (
                      <option key={index} value={items.sub_category_id}>
                        {items.item_category_name} -{" "}
                        {items.item_sub_category_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Category ID
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Main Category
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Sub Category
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Category Description
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item._id} className="bg-white text-center">
                    <td className="py-4">{item.sub_category_id}</td>
                    <td className="py-4">{item.item_category_name}</td>
                    <td className="py-4">{item.item_sub_category_name}</td>
                    <td className="py-4">{item.item_category_description}</td>
                    <td className="py-4">
                      {hasPermission(1660) && (
                        <button
                          className=" px-2 py-1 rounded"
                          onClick={() => handleDeleteClick(item)}
                        >
                          <MdDelete className="text-[#FF0000] " />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-center mt-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrPrevious className="w-3 h-3" />
            </button>
            {Array.from({
              length: Math.ceil(filteredStoreData.length / itemsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`${
                  currentPage === index + 1
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredStoreData.length / itemsPerPage)
              }
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrNext className="w-3 h-3" />
            </button>
          </div>
        </div>

        {showConfirmationModal && (
          <ConfirmationModal
            message="Are you sure you want to delete this?"
            onCancel={handleCancelDelete}
            onConfirm={handleDeleteConfirmed}
          />
        )}
      </div>
    </div>
  );
};

export default View_Item_Category;
