import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import TableBidders from "./tabel";
import TableBidderEvaluation from "./biddersEvaluationTabel";
import DialogMemberDetails from "./memberDetailsDialog";
import DialogBidderDetails from "./bidderDetailsDialog";
import uploadImage from "../../../../Assets/icons/uploadImage.png";
import DialogMeetingReportUp from "./meetingReportUpDialog";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";

const Technical_Members = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSup, setSearchSup] = useState("");
  const [tenders, setTenders] = useState([]);
  const [supliers, setSupliers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [tednerId, setTenderId] = useState("");
  const [tenderName, setTenderName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [criteria, setCriteria] = useState("");
  const [percentage, setpercentage] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/search/tender/${query}`
        );
        setTenders(response.data);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleSearchContractChange = async (event) => {
    const query = event.target.value;
    setSearchSup(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
        );
        setSupliers(response.data);
        setIsCustomerDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSupliers([]);
      }
    } else {
      setSupliers([]);
      setIsCustomerDropdownOpen(false);
    }
  };

  const handleSubmit = async () => {
    try {
      // Create the JSON body
      const body = {
        tender_id: tednerId,
        criteria: criteria,
        percentage: percentage,
      };

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/add`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Criteria add successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Criteria");
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  };

  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);

    //Assign values to the fetching input field.
    const tenderDateParts = result.deadline_date.split("/");
    const tenderDate = new Date(
      `${tenderDateParts[2]}/${tenderDateParts[1]}/${tenderDateParts[0]}`
    );
    setAppointingDate(tenderDate);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
    setSupplierName(result.contract_id);
  };

  const bidEvaluationSummaryReport = () => {
    navigate("/committess/bidEvaluationSummaryReportCreate", {
      state: selectedTender,
    });
  };

  const bidEvaluationSummaryReportView = () => {
    navigate("/committess/bidEvaluationSummaryReportView", {
      state: selectedTender,
    });
  };

  const createCommiteeMeetingReport = () => {
    navigate("/committess/committeeMeetingReportCreate", {
      state: selectedTender,
    });
  };

  const viewCommiteeMeetingReport = () => {
    navigate("/committess/committeeMeetingReportView", {
      state: selectedTender,
    });
  };

  const [appointingDate, setAppointingDate] = useState(null);

  const [showDialog, setShowDialog] = useState(false);
  const [detailsShowDialog, setDetailsShowDialog] = useState(false);
  const [showMeetingsDialog, setshowMeetingsDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDetailDialog = () => {
    setDetailsShowDialog(true);
  };

  const closeDetailDialog = () => {
    setDetailsShowDialog(false);
  };

  const openMeetingReportDialog = () => {
    setshowMeetingsDialog(true);
    console.log("Im here...");
  };

  const closeMeetingReportDialog = () => {
    setshowMeetingsDialog(false);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Technical Evaluation Committee
      </p>

      {hasPermission(2651) && (
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-6 right-[1%]">
          <div className="min-h-[110px] min-w-[150px]">
            <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
              View Committee <br /> Member Details
            </h2>
          </div>
          <div className="flex items-center relative">
            <button
              onClick={openDialog}
              className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
            >
              Click Here
            </button>

            <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
          </div>

          {showDialog && <DialogMemberDetails onClose={closeDialog} />}
        </div>
      )}
      {hasPermission(2653) && (
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-[35%] right-[1%]">
          <div className="min-h-[110px] min-w-[150px]">
            <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
              View Bidder <br /> Details
            </h2>
          </div>
          <div className="flex items-center relative">
            <button
              onClick={openDetailDialog}
              className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
            >
              Click Here
            </button>

            <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
          </div>

          {detailsShowDialog && (
            <DialogBidderDetails onClose={closeDetailDialog} />
          )}
        </div>
      )}
      {hasPermission(2650) && (
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-6 right-[27%]">
          <div className="min-h-[110px] min-w-[150px]">
            <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
              Bid Evaluation <br /> Summary Report
            </h2>
          </div>
          <div className="flex items-center relative">
            {hasPermission(10031) && (
              <button
                onClick={bidEvaluationSummaryReport}
                className="bg-primary w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
              >
                Create
              </button>
            )}
            {hasPermission(10030) && (
              <button
                onClick={bidEvaluationSummaryReportView}
                className="bg-[#797C80] w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10 ml-2"
              >
                View
              </button>
            )}

            <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
          </div>
        </div>
      )}
      <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-[35%] right-[27%]">
        {hasPermission(2652) && (
          <div className="min-h-[110px] min-w-[150px] flex justify-between">
            <div>
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Meeting <br /> Report
              </h2>
            </div>
            <div>
              <button onClick={openMeetingReportDialog}>
                <img
                  src={uploadImage}
                  alt="uploadImage"
                  className="h-20 w-20 mt-2"
                />
              </button>
            </div>
            {showMeetingsDialog && (
              <DialogMeetingReportUp
                onClose={closeMeetingReportDialog}
                data={tednerId}
              />
            )}
          </div>
        )}
        <div className="flex items-center relative">
          {hasPermission(10041) && (
            <button
              onClick={createCommiteeMeetingReport}
              className="bg-primary w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
            >
              Create
            </button>
          )}
          {hasPermission(10040) && (
            <button
              onClick={viewCommiteeMeetingReport}
              className="bg-[#797C80] w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10 ml-2"
            >
              View
            </button>
          )}

          <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
        </div>

        {detailsShowDialog && (
          <DialogBidderDetails onClose={closeDetailDialog} />
        )}
      </div>
      <div className="mt-6 ml-10">
        {/*Search Box*/}
        <div className="flex flex-col font-poppins font-bold mt-10 ml-6 ">
          <form className="relative">
            <div className="flex items-center relative">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
              <button
                type="button"
                className="btn btn-primary text-[#797C80] hover:bg-gray-100 ml-2"
                onClick={handleReset}
              >
                RESET
              </button>
            </div>
            <div className="mt-4 relative">
              {isDropdownOpen && (
                <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                  {tenders.length > 0 ? (
                    tenders.map((result) => (
                      <li
                        key={result._id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        value={selectedTender}
                        onClick={() => handleOptionClick(result)}
                      >
                        {result.tender_id}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 text-gray-500">No results found</li>
                  )}
                </ul>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="mt-6 ml-10 flex">
        <DatePicker
          selected={appointingDate}
          onChange={(date) => setAppointingDate(date)}
          className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF]"
          placeholderText="dd-mm-yyyy"
          disabled
        />
        <div className="ml-20">
          {" "}
          <button className="btn btn-primary text-[#BBC5DC] text-[20px]">
            Select Date
          </button>
        </div>
      </div>
      <div className="mt-6">
        <label className="mt-[20px] ml-10 text-[22px] text-[#9CAC8B] font-family-[Poppins] font-bold">
          *This Committee should have minimum of 4 members
        </label>
      </div>
      <div className="mt-2">
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 ml-10 min-w-[40%] shadow-md  card absolute grid grid-rows-2  gap-6 text-[20px] font-bold ">
          <div className="ml-4">
            <label>Tender ID:</label>
            <input
              className="input ml-[17%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
              placeholder="Enter Tender ID"
              value={tednerId}
              onChange={(e) => setTenderId(e.target.value)}
              disabled
            ></input>
          </div>
          <div className="ml-4">
            <label>Tender Name</label>
            <input
              className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
              placeholder="Enter Tender Name"
              value={tenderName}
              onChange={(e) => setTenderName(e.target.value)}
              disabled
            ></input>
          </div>
        </div>
      </div>
      <div className="mt-[12%]">
        <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
          Tender Updates of Bidders
        </p>
      </div>
      <TableBidders tednerId={tednerId} />

      <div className="mt-[2%]">
        <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
          Add Evaluation Criteria
        </p>
      </div>

      <div className="grid grid-rows-2">
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 ml-10 min-w-[90%] shadow-md justify-between card absolute grid grid-cols-5 gird-row-2 gap-6 text-[20px] font-bold ">
          <label>Tender Name:</label>
          <input
            className="input ml-[17%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            value={tenderName}
            onChange={(e) => setTenderName(e.target.value)}
            disabled
          ></input>

          <label>Contract ID:</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
            disabled
          ></input>
          <div></div>

          <label>Criteria:</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            value={criteria}
            onChange={(e) => setCriteria(e.target.value)}
            required
          ></input>
          <label>Percentage</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            placeholder="10"
            value={percentage}
            onChange={(e) => setpercentage(e.target.value)}
            type="number"
            required
          ></input>
          {hasPermission(2654) && (
            <button
              onClick={handleSubmit}
              className="bg-primary max-w-[50%]  text-white font-bold right-6 rounded-[22px] "
            >
              Add
            </button>
          )}
        </div>

        <div className="flex flex-between font-poppins font-bold mt-6 ml-10 "></div>
        <div className="mt-[4%] flex justify-between">
          <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
            Evaluation of the Bidders.
          </p>
          {hasPermission(2655) && (
            <button
              // onClick={openDialog}
              className="bg-primary min-w-40 text-white font-bold mr-10 rounded-[22px] "
            >
              Export as CSV
            </button>
          )}
        </div>
      </div>
      <TableBidderEvaluation tednerId={tednerId} />
    </div>
  );
};

export default Technical_Members;
