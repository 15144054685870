/** @format */

import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import "./user-pag.css";
import ConfirmationModal from "./confirmation_modal";
import { Link, useNavigate } from "react-router-dom";
import usePermissions from "../../components/permissions/permission";

const User = () => {
  const { hasPermission } = usePermissions();
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const itemsPerPage = 6;
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/user/getRoles`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setRoles(data);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const handleRowSelect = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((i) => i !== index)
        : [...prevSelectedRows, index]
    );
  };
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/user/deleteUserRole?role_id=${itemToDelete.id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          console.log("Deleted item:", itemToDelete);
          setShowConfirmationModal(false);
          setItemToDelete(null);
          // Refresh the roles list after deletion
          fetchRoles();
        } else {
          console.error("Failed to delete item");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setItemToDelete(null);
  };

  const pageCount = Math.ceil(roles.length / itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const HandleOnClick = (role) => {
    // Navigate to the new page and pass the object
    navigate("/settings_permission", {
      state: { selectedRecord: role, updatestatus: true },
    });
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = roles.slice(offset, offset + itemsPerPage);

  return (
    <div className="bg-background overflow-y-auto h-screen overflow-x-hidden">
      <p className="mt-10 ml-6 text-headerColor text-[28px] font-semibold">
        Group Permissions
      </p>

      <div className="bg-[#F4F7FCBF] rounded-[6px] opacity-75 w-[90%] h-[70%] ml-[20px] mt-[20px] mb-10">
        <div className="flex justify-between items-center p-5">
          <div className="ml-[20px]">
            <input
              className="bg-white border mt-[10px] border-gray rounded-[20px]"
              placeholder="Search"
            />
          </div>
          <div>
            <Link to="/settings_permission">
              {hasPermission(1960) && (
                <button className="bg-[#2264E5] text-white flex gap-2 items-center p-2 rounded-[35px]">
                  <FaPlus />
                  Create
                </button>
              )}
            </Link>
          </div>
        </div>

        {/* table */}
        <div className="w-[90%] ml-[50px] mt-[20px]">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 text-center">#</th>
                <th className="py-2 px-4 text-center">Role</th>
                <th className="py-2 px-4 text-center">Description</th>
                <th className="py-2 px-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((role, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRows.includes(index)
                      ? "bg-gray-200"
                      : index % 2 === 1
                      ? "bg-[#F4F7FCBF]"
                      : "bg-white"
                  }`}
                >
                  <td className="py-2 px-4 text-center">
                    {offset + index + 1}
                  </td>
                  <td className="py-2 px-4 text-center">{role.role_name}</td>
                  <td className="py-2 px-4 text-center">
                    {role.role_description}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {hasPermission(1961) && (
                      <button
                        className=" px-2 py-1 rounded"
                        onClick={() => HandleOnClick(role)}
                      >
                        <CiEdit />
                      </button>
                    )}

                    {hasPermission(1962) && (
                      <button
                        className=" px-2 py-1 rounded"
                        onClick={() => handleDeleteClick(role)}
                      >
                        <MdDelete className="text-[#FF0000] " />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this?"
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </div>
  );
};

export default User;
