/** @format */

import React, { useState, useEffect } from "react";

function ViewMore({ viewItems, onClose }) {
  // Move hooks to the top of the component to ensure they are called unconditionally
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Set loading to false after the initial render, assuming data is passed in
  useEffect(() => {
    if (viewItems) {
      setLoading(false);
    } else {
      setError(new Error("No Procurement data found."));
      setLoading(false);
    }
  }, [viewItems]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!viewItems || viewItems.length === 0)
    return <p>No Procurement items found.</p>;

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full ">
      <div className="bg-white p-5 w-[800px] h-[70%] rounded-xl  relative overflow-auto">
        <div className="mb-6">
          <p className="font-sans text-[32px] font-bold text-center">
            Procurement Items
          </p>
          <div
            className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item overflow-y-auto"
            style={{ maxHeight: "50vh" }}
          >
            <table className="table-auto border-none w-[95%] ">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Item ID</th>
                  <th className="text-primary px-4 py-2">Item Name</th>
                  <th className="text-primary px-4 py-2">Item Qty</th>
                  <th className="text-primary px-4 py-2">Item Measure Unit</th>
                </tr>
              </thead>
              <tbody>
                {viewItems.map((item, index) => (
                  <tr key={index} className="">
                    <td className="bg-white px-4 py-2 text-center">
                      {item.item_id}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.item_name}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.item_qty}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.item_measure_unit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewMore;
