/** @format */

import React, { useState, useEffect } from "react";

function CustomSlider({ levels, value, onChange }) {
  const handleDrag = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const width = rect.width;
    const newValue = Math.round((offsetX / width) * (levels.length - 1));
    onChange(levels[newValue]);
  };

  const getBarColor = (level) => {
    switch (level) {
      case "Low":
        return "bg-green-400";
      case "Medium":
        return "bg-yellow-400";
      case "High":
        return "bg-orange-400";
      case "Very High":
        return "bg-red-400";
      default:
        return "";
    }
  };

  return (
    <div className="w-[600px] relative">
      <div
        className="h-3 w-full rounded-full bg-gray-300 relative"
        onMouseDown={handleDrag}
      >
        <div
          className={`h-3 ${getBarColor(
            value
          )} absolute top-0 left-0 rounded-full`}
          style={{
            width: `${(levels.indexOf(value) / (levels.length - 1)) * 100}%`,
          }}
        ></div>
      </div>
      <div className="flex justify-between mt-1">
        {levels.map((level, index) => (
          <span
            key={index}
            className={`text-xs ${
              level === value ? "font-bold text-gray-900" : "text-gray-600"
            }`}
          >
            {level}
          </span>
        ))}
      </div>
      <div
        className="absolute top-0 ml-5 h-4 w-2 bg-white border border-gray-300 rounded-full shadow cursor-pointer"
        style={{
          left: `${(levels.indexOf(value) / (levels.length - 1)) * 100 - 4}%`,
          transform: "translate(-50%, -50%)",
        }}
        onMouseDown={handleDrag}
      ></div>
    </div>
  );
}

function CreateJob() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const levels = ["Low", "Medium", "High", "Very High"];
  const [value, setValue] = useState(levels[0]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [recipe_id, setRecipeId] = useState(""); // State to hold the selected recipe ID
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    fetchUnitOptions();
    fetchRecipes();
  }, []);

  const fetchRecipes = () => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getAccordingtoStatus?status=APPROVE`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch recipes");
        }
        return response.json();
      })
      .then((data) => {
        setRecipes(data);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  };

  const fetchUnitOptions = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/get`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch unit options");
        }
      })
      .then((data) => {
        console.log(" fetching unit options:", data);
        setUnitOptions(
          data.map((unit) => ({
            id: unit._id,
            name: unit.unit_name,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching unit options:", error);
      });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (e) => {
    setSelectedRecipe(e.target.value);
    const selectedRecipe = recipes.find(
      (recipe) => recipe.recipe_name === e.target.value
    );
    if (selectedRecipe) {
      setRecipeId(selectedRecipe.recipe_id); // Update the recipe_id state with the selected recipe ID
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      job_name: event.target.job_name.value,
      job_recipe_id: recipe_id, // Include the recipe_id here
      job_category: event.target.job_category.value,
      job_quater: event.target.job_quater.value,
      job_quantity: event.target.Quantity.value,
      job_unit_id: event.target.job_unit_id.value,
      job_status: "PENDING",
      job_created_by: "EMP-003",
      job_priority_level: value,
    };

    console.log("Request Body:", formData);

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/job/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Job added successfully");
          setShowSuccessMessage(true);
          // Reset form values
          event.target.reset();
          setSelectedRecipe(""); // Reset the selected recipe
          setRecipeId(""); // Reset the recipe_id
          setValue(levels[0]); // Reset the priority level
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 2000);
        } else {
          console.error("Failed to add job");
          alert("Failed to add job"); // Display error message
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred. Please try again."); // Display error message
      });
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Create Job
      </p>
      <div className="box-border w-full p-4 bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="ml-8">
              <div className="sm:col-span-3 flex flex-row items-center">
                <label
                  htmlFor="job_name"
                  className="text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Job name
                </label>
                <input
                  type="text"
                  name="job_name"
                  id="job_name"
                  autoComplete="job_name"
                  className="w-[50%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                />
              </div>
              <br />
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor={`job_recipe_id`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Select Job Recipe
                </label>
                <div className="flex flex-row items-center">
                  <select
                    id={`job_recipe_id`}
                    name={`job_recipe_id`}
                    autoComplete="job_recipe_id"
                    className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    onChange={handleSelectChange}
                    value={selectedRecipe}
                  >
                    <option value="">Select Recipe</option>
                    {recipes.map((recipe) => (
                      <option
                        key={recipe.recipe_name}
                        value={recipe.recipe_name}
                      >
                        {recipe.recipe_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" sm:col-span-3 flex flex-row items-center mt-7">
                <label
                  htmlFor={`job_category`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4 mt-5"
                >
                  Job Category
                </label>
                <div className=" flex items-center gap-x-6 ml-[10px] mt-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="job_category"
                      name="job_category"
                      value="End Product"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="option1"
                      className="ml-2 text-sm text-gray-700"
                    >
                      End Product
                    </label>
                  </div>
                  <div className=" flex items-center">
                    <input
                      type="radio"
                      id="option2"
                      name="job_category"
                      value="Semi Product"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="option2"
                      className="ml-2 text-sm text-gray-700"
                    >
                      Semi Product{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor="job_quater"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Choose a Quarter{" "}
                </label>
                <div className="mt-2 flex flex-row items-center">
                  <select
                    id="job_quater"
                    name="job_quater"
                    autoComplete="job_quater"
                    className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>First Quarter</option>
                    <option>Second Quarter</option>
                    <option>Third Quarter</option>
                    <option>Fourth Quarter</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-row items-center gap-x-8">
                <div className="sm:col-span-2 sm:col-start-1 flex flex-row items-center mt-8 mb-8">
                  <label
                    htmlFor={`Quantity`}
                    className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                  >
                    Quantity
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="Quantity"
                      id="Quantity"
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ml-6 pl-5 px-4"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 flex flex-row items-center ml-11">
                  <label
                    htmlFor={`job_unit_id`}
                    className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                  >
                    Unit
                  </label>
                  <div className="mt-2 flex flex-row items-center">
                    <select
                      id="job_unit_id"
                      name="job_unit_id"
                      autoComplete="job_unit_id"
                      className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      {unitOptions.map((unit) => (
                        <option key={unit.id} value={unit.id}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Priority Level
                </label>
                <div className="flex flex-row items-center mt-5">
                  <CustomSlider
                    levels={levels}
                    value={value}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mt-1 flex items-center gap-x-6">
                <button
                  type="submit"
                  className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
                >
                  Submit
                </button>
                {showSuccessMessage && (
                  <div className="text-green-600 text-sm mt-2">
                    File submitted successfully!
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJob;
