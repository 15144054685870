import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiFile } from "react-icons/fi";
import DialogTenderView from "./tenderViewDialog";
import axios from "axios";
const TableBidders = ({ tednerId }) => {
  const [biddertableData, setBidderTableData] = useState([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleSearchChange = async () => {
    if (tednerId) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_bidders/${tednerId}`
        );
        setBidderTableData(response.data);
        console.log("Data:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBidderTableData([]);
      }
    } else {
      setBidderTableData([]);
    }
  };
  const navigate = useNavigate();
  console.log("Tender ID:", tednerId);

  const [tenderViewShowDialog, setTenderViewShowDialog] = useState(false);

  const openTenderViewDialog = (documentName) => {
    setSelectedDocumentName(documentName);
    setTenderViewShowDialog(true);
  };

  const closeTenderViewDialog = () => {
    setTenderViewShowDialog(false);
    setSelectedDocumentName(null);
  };
  useEffect(() => {
    handleSearchChange();
  }, [tednerId]);

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = biddertableData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[96%] ml-10 max-h-[100%] overflow-y-auto mb-8 mt-6">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Tender ID</th>
            <th className="px-6 py-6 ">Tender Name</th>
            <th className="px-6 py-6 ">Contract ID</th>
            <th className="px-6 py-6 ">Note</th>
            <th className="px-6 py-6 ">Document</th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.tender_id}</td>
              <td className="px-6 py-2">{item.supplier_name}</td>
              <td className="px-6 py-2">{item.supplier_id}</td>
              <td className="px-6 py-2">{item.bidder_note}</td>
              <td className="px-6 py-2">
                <button
                  onClick={() =>
                    openTenderViewDialog(item.bidder_document_name)
                  }
                  className="bg-primary min-h-10 min-w-[100px] text-white font-bold rounded-[22px]"
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {tenderViewShowDialog && (
        <DialogTenderView
          onClose={closeTenderViewDialog}
          document={selectedDocumentName}
        />
      )}
    </div>
  );
};

export default TableBidders;
