/** @format */

import React from "react";
import usePermissions from "../../../components/permissions/permission";
import { Link } from "react-router-dom";

const Approve_3 = () => {
  const { hasPermission } = usePermissions();
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="ml-[6%] mt-[5%] mr-[3%]">
        <p className="text-headerColor text-[28px] font-semibold">
          Approval Stage Three
        </p>
        <div className="grid grid-cols-7 gap-8 mt-8">
          {/* card5 */}
          {hasPermission(1990) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">Inventory</p>
                <p>Inventory Dispose Approvals</p>
                <div>
                  <Link to="/approval3-dispose">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* card6 */}
          {hasPermission(1991) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">Inventory</p>
                <p>Inventory Adjust Approvals</p>
                <div>
                  <Link to="/approval3-adjusted">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Approve_3;
