/** @format */

import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import usePermissions from "../../../components/permissions/permission";
const RejectedRecipes = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [rejectedRecipes, setRejectedRecipes] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getAccordingtoStatus?status=REJECTED`
    )
      .then((response) => response.json())
      .then((data) => {
        setRejectedRecipes(data);
      })
      .catch((error) => {
        console.error("Error fetching rejected recipes:", error);
      });
  }, []);

  // Filter data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = rejectedRecipes.slice(startIndex, endIndex);
  console.log("rejected data", filteredData);

  const totalPages = Math.ceil(rejectedRecipes.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // New function to handle recipe click
  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };
  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedRecipe(null);
  };

  console.log("selected data", selectedRecipe);
  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl ">
        Rejected Recipes
      </p>
      <div className="flex mt-10">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Rejected Recipe
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Reason for Rejection
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((recipe, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{recipe.recipe_name}</td>
                <td className="text-center">{recipe.recipe_reject_reason}</td>
                <td className="text-center">
                  {hasPermission(2411) && (
                    <button
                      onClick={() => handleRecipeClick(recipe)}
                      className="text-gray-400 hover:text-gray-800 focus:outline-none"
                    >
                      <FaEye />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}

      {selectedRecipe && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[500px]">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4">Recipe Details</h2>
            <p className="text-lg">Recipe Name: {selectedRecipe.recipe_name}</p>
            <p className="text-lg">
              Description: {selectedRecipe.recipe_discription}
            </p>
            <p className="text-lg">Status: {selectedRecipe.recipe_status}</p>
            <p className="text-lg">
              Created By: {selectedRecipe.recipe_create_by}
            </p>
            <p className="text-lg">Rejected By: {selectedRecipe.recipe_dr}</p>
            <p className="text-lg">
              Rejection Reason: {selectedRecipe.recipe_reject_reason}
            </p>

            {selectedRecipe.raw_item && selectedRecipe.raw_item.length > 0 && (
              <div>
                <p className="text-lg font-bold mb-4 ">Raw Items:</p>
                <ul>
                  {selectedRecipe.raw_item.map((item, index) => (
                    <li key={index} className="mb-2">
                      <p>Item ID: {item.raw_item_id}</p>
                      <p>Quantity: {item.raw_item_quantity}</p>
                      <p>Unit: {item.raw_item_unit}</p>
                      {item.alternative_item &&
                        item.alternative_item.length > 0 && (
                          <div className="ml-4">
                            <p className="text-lg">Alternative Items:</p>
                            <ul>
                              {item.alternative_item.map(
                                (alternative, altIndex) => (
                                  <li
                                    key={altIndex}
                                    className="bg-yellow-200 p-2 rounded-md mb-2"
                                  >
                                    <p className="font-bold">
                                      Alternative Item Name:{" "}
                                      {alternative.raw_item_name}
                                    </p>
                                    <p>
                                      Quantity: {alternative.raw_item_quantity}
                                    </p>
                                    <p>Unit: {alternative.raw_item_unit}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RejectedRecipes;
