/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../Assets/icons/csv.png";
import usePermissions from "../../../../components/permissions/permission";

const ProductionInventoryRequest = ({ itemReq }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItemReq, setselectedItemReq] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchProReq();
  }, []);

  const fetchProReq = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/production-InventoryRequest-data`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching production req data:", error);
    }
  };

  const HandleOnClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("/inventory/view-pro-items-req", {
      state: { selectedRecord: itemReq },
    });
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Production Item Requests Details
      </p>
      <div className="flex justify-end overflow-hidden pb-6 mb-16">
        {/* <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-1 w-100px">
              Released Production Item Requests
            </h2>
          </div>
          {hasPermission(2310) && (
            <div className="flex justify-between items-center">
              <Link to={``}>
                <button className="bg-primary w-[100%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                  Click Here
                </button>
              </Link>
              <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
            </div>
          )}
        </div> */}
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Job ID</th>
              <th className="text-primary px-6 py-4">Production Manager</th>
              <th className="text-primary px-6 py-4">Production Description</th>
              <th className="text-primary px-6 py-4">Production Create at</th>
              <th className="text-primary px-6 py-4">Production Status</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {items.map((itemReq) => (
              <tr key={itemReq.job_id}>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.job_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_manager}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_request_discription}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_create_at}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_status}
                </td>

                <td className="bg-white px-6 py-4 text-center">
                  {hasPermission(2311) && (
                    <button
                      className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                      onClick={() => HandleOnClick(itemReq)}
                      // setselectedItemReq={itemReq}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductionInventoryRequest;
