import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
// import AddManualDisposeRecord from './addManualDisposeRecord'
// import  EditManualDisposeRecord from './editManualDisposeRecord'

const SampleTestingreport = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableRest, setTableReset] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpenf state
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  // Define state for start date and end date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [row, setRow] = useState();
  const [editOpen, setEditOpen] = useState();
  const [notification, setNotification] = useState({ message: "", type: "" });

  const rowsPerPage = 5; // Number of rows per page

  // Calculate the number of pages
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // Get the current rows for the page
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Helper function to format date to 'YYYY-MM-DD'
  const formatDateForInput = (date) => {
    if (!date) return ""; // Return an empty string if date is invalid
    const formattedDate = new Date(date);
    return isNaN(formattedDate) ? "" : formattedDate.toISOString().slice(0, 10); // Format to 'YYYY-MM-DD'
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };
  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Function to handle CSV export
  const handleExportCSV = () => {
    // const csvData = tableData.map((obj) => ({
    //   SampleID: obj.sample_id,
    //   SampleType: obj.sample_type,
    //   SampleName: obj.sample_name,
    //   Amount: obj.amount,
    //   TenderBulkReceivedDate: obj.received_date,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "Sample_Allocation_data.csv");
  };
  // Function to handle PDF export
  const handleExportPDF = () => {
    // const doc = new jsPDF();
    // doc.text("Sample Allocation", 14, 16);
    // // Define columns and data
    // const columns = [
    //   { header: "Sample ID", dataKey: "sample_id" },
    //   { header: "Sample Type", dataKey: "sample_type" },
    //   { header: "Sample Name", dataKey: "sample_name" },
    //   { header: "Amount", dataKey: "amount" },
    //   { header: "Tender/ Bulk Received Date", dataKey: "received_date" },
    // ];
    // const data = tableData.map((row) => ({
    //   sample_id: row.sample_id,
    //   sample_type: row.sample_type,
    //   sample_name: row.sample_name,
    //   amount: row.amount,
    //   received_date: new Date(row.received_date).toLocaleDateString("en-US"),
    // }));
    // // Add table to PDF
    // doc.autoTable(columns, data);
    // doc.save("sample_allocation.pdf");
  };

  // Function to handle start date change
  const handleStartDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value); // Format the date

    setStartDate(formattedDate);
  };

  // Function to handle end date change
  const handleEndDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value); // Format the date

    setEndDate(formattedDate);
  };

  // Function to handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const editSampleAllocation = (row) => {
    setRow(row);
    setEditOpen(true);
  };

  const closeEditModal = () => {
    setEditOpen(!editOpen);
  };

  const getTableData = async () => {
    try {
      const params = {};

      if (searchTerm) {
        params.sample_id = searchTerm;
      }
      if (startDate) {
        params.start_date = startDate;
      }
      if (endDate) {
        params.end_date = endDate;
      }

      const response = await axios.get(
        `${backendUrl}/qualityApprovals/getApproval1Bulk`,
        {
          params,
        }
      );

      console.log(response.data)
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  const deleteSampleAllocation = (rowId) => {
    setDeleteRowID(rowId);
    setDeleteConfirmationOpen(true);
  };

  // table rest
  if (tableRest == true) {
    getTableData();
    setTableReset(false);
  }

  const handleDeleteForm = async () => {
    try {
      const response = await axios.delete(
        `${backendUrl}/qualitySamples/deleteSamples`,
        {
          params: {
            sample_id: deleteRowID,
          },
        }
      );

      setNotification({
        message: "Sample details deleted successfully!",
        type: "success",
      });
      getTableData();
    } catch (error) {
      console.error("Error deleting item:", error);
      setNotification({
        message: "Error deleting sample details. Please try again.",
        type: "error",
      });
    }

    setDeleteConfirmationOpen(false);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteRowID("");
    setDeleteConfirmationOpen(false);
  };

  const handleSampleTypeChange = (e) => {};

  const handleDurationChange = (e) => {};

  


  useEffect(() => {
    getTableData();
  }, [searchTerm ]);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold"> Approval 01 : Sample Testing Report</p>
          </div>

          {/* Align Add Button to the Top Right */}
          {/* <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            onClick={togglePopup}
          >
            <IoAdd className="text-xl" />
            Add Sample
          </button> */}
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:gap-4">
          {/* Search Box with Manual Width */}
          <div className="w-full mt-6 md:w-[300px]">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

  
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-14">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Employee Number
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample ID
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Type
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Descripting
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Report
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Approval
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {/* {currentRows.map((row) => (
              <tr key={row.sample_id}>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_id}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.amount}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.received_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long", // Use "short" for abbreviated month names
                    day: "2-digit",
                  })}
                </td>

                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  <button
                    className="p-2 text-blue-500 rounded hover:bg-blue-100"
                    onClick={() => editSampleAllocation(row)}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="p-2 ml-2 text-red-500 rounded hover:bg-red-100"
                    onClick={() => deleteSampleAllocation(row.sample_id)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 text-sm rounded ${
              currentPage === i + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Add Sample Allocation Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddManualDisposeRecord
            togglePopup={togglePopup}
            resetTable={setTableReset}
            />
          </div>
        </div>
      )} */}

      {/* Edit Sample Allocation Process Popup */}
      {/* {editOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
          <EditManualDisposeRecord togglePopup={closeEditModal}/>
          </div>
        </div>
      )} */}

      {/* Delete Confirmation */}
      {deleteConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
            <p>
              Are you sure you want to reset the form? This action cannot be
              undone.
            </p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-xl"
                onClick={handleDeleteForm}
              >
                Yes, Delete
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseDeleteConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleTestingreport;
