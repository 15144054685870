/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../components/permissions/permission";

function CreateRecipe() {
  const { hasPermission } = usePermissions();
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeDoctor, setRecipeDoctor] = useState("");
  const [rawItems, setRawItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [rawItemIds, setRawItemIds] = useState({});
  const [itemSections, setItemSections] = useState(1);
  const [selectedPrimaryRawItems, setSelectedPrimaryRawItems] = useState(
    Array(itemSections).fill("")
  );
  const [selectedAlternativeRawItems, setSelectedAlternativeRawItems] =
    useState(Array(itemSections).fill(""));
  const [showAlternatives, setShowAlternatives] = useState(
    Array(itemSections).fill(false)
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [recipeNameError, setRecipeNameError] = useState("");
  const [recipeIngredientError, setRecipeIngredientError] = useState("");
  const [recipeDoctorError, setRecipeDoctorError] = useState("");
  const [recipeNoteError, setRecipeNoteError] = useState("");
  const [recipeQuantityError, setRecipeQuantityError] = useState("");
  const [recipeUnitError, setRecipeUnitError] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [rawItemUnits, setRawItemUnits] = useState(
    Array(rawItems.length).fill("")
  );
  const [altrawItemUnits, setAlternativeRawItemUnits] = useState(
    Array(rawItems.length).fill("")
  );
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchDoctors = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/employees/production-employees`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch doctors options");
        }
      })
      .then((data) => {
        console.log("Fetching doctors options:", data);
        // Filter the data to include only doctors
        const doctors = data.filter(
          (employee) => employee.designation === "Doctors"
        );
        // Map the filtered doctors to the desired format
        setDoctors(
          doctors.map((doctor) => ({
            id: doctor.employee_no,
            name: doctor.employee_name_initial,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching doctors options:", error);
      });
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/rawitem/allrawitem`)
      .then((response) => response.json())
      .then((data) => {
        const rawItemDetails = data.reduce((acc, item) => {
          acc[item.raw_item_name] = {
            id: item.raw_item_id,
            unit: item.raw_item_measure_unit,
          };
          return acc;
        }, {});
        setRawItemIds(rawItemDetails);
        setRawItems(data.map((item) => item.raw_item_name));
        // Prepare units state to store the unit for each raw item
        setUnits(
          data.reduce((acc, item) => {
            acc[item.raw_item_name] = item.raw_item_measure_unit; // Assuming this is where the unit data is stored
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.error("Error fetching raw items:", error);
      });
  }, []);

  // handleRawItemChange function
  const handleRawItemChange = (event, index, isPrimary) => {
    const selectedRawItemName = event.target.value;
    const selectedUnit = units[selectedRawItemName]; // Get unit for selected raw item

    if (isPrimary) {
      setSelectedPrimaryRawItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = selectedRawItemName;
        return updatedItems;
      });
      setRawItemUnits((prevUnits) => {
        const updatedUnits = [...prevUnits];
        updatedUnits[index] = selectedUnit;
        return updatedUnits;
      });
    } else {
      setSelectedAlternativeRawItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = selectedRawItemName;
        return updatedItems;
      });
      setAlternativeRawItemUnits((prevUnits) => {
        const updatedUnits = [...prevUnits];
        updatedUnits[index] = selectedUnit;
        return updatedUnits;
      });
    }
  };

  const toggleAlternatives = (index) => {
    setShowAlternatives((prev) => {
      const updatedShowAlternatives = [...prev];
      updatedShowAlternatives[index] = !updatedShowAlternatives[index];
      return updatedShowAlternatives;
    });
  };

  const handleAddItemSection = () => {
    setItemSections((prev) => prev + 1);
    setSelectedPrimaryRawItems((prev) => [...prev, ""]);
    setSelectedAlternativeRawItems((prev) => [...prev, ""]);
    setShowAlternatives((prev) => [...prev, false]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      recipeName.trim() === "" ||
      recipeDescription.trim() === "" ||
      selectedPrimaryRawItems.some((item) => item.trim() === "") ||
      !selectedPrimaryRawItems.every((item, index) => {
        const quantity = document.getElementById(
          `raw_item_quantity_${index}`
        )?.value;
        const unit = document.getElementById(`raw_item_unit_${index}`)?.value;
        return quantity.trim() !== "" && unit.trim() !== "";
      })
    ) {
      return;
    }

    // Constructing the raw item data
    const rawItemsData = [];
    // Iterate over each item section
    for (let i = 0; i < itemSections; i++) {
      const rawItemName = selectedPrimaryRawItems[i];
      const raw_item_id = rawItemIds[rawItemName]; // Fetching raw item ID from rawItemIds object
      const rawItemQuantity = document.getElementById(
        `raw_item_quantity_${i}`
      )?.value;
      const rawItemUnit = document.getElementById(`raw_item_unit_${i}`)?.value; // Fetching selected unit

      // Initialize array for alternative items
      const alternativeItems = [];

      // Get alternative item details from state
      const alternativeItemName = selectedAlternativeRawItems[i];
      const alternativeItemId = rawItemIds[alternativeItemName]; // Fetching alternative raw item ID from rawItemIds object
      const alternativeItemQuantity = document.getElementById(
        `alternative_raw_item_quantity_${i}`
      )?.value;
      const alternativeItemUnit = document.getElementById(
        `raw_item_unit_${i}`
      )?.value;

      // Add alternative item if present
      if (alternativeItemId) {
        const alternativeItem = {
          raw_item_id: alternativeItemId.id, // Extracting only the ID value
          raw_item_quantity: alternativeItemQuantity,
          raw_item_unit: alternativeItemUnit,
        };
        alternativeItems.push(alternativeItem);
      }

      // Construct raw item object
      const rawItem = {
        raw_item_id: raw_item_id.id, // Extracting only the ID value
        raw_item_quantity: rawItemQuantity,
        raw_item_unit: rawItemUnit, // Include selected unit
        alternative_item: alternativeItems, // Include alternative items
      };

      rawItemsData.push(rawItem);
    }

    // Constructing the complete form data
    const formData = {
      recipe_name: recipeName,
      recipe_discription: recipeDescription,
      raw_item: rawItemsData,
      recipe_status: "PENDING",
      recipe_dr: recipeDoctor,
      recipe_create_by: "EMP-007",
    };
    // Save form data to local storage
    localStorage.setItem("formData", JSON.stringify(formData));
    console.log("Request Body:", formData);
    // Sending the POST request
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/recipe/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Recipe added successfully");
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 4000);
          setRecipeName("");
          setRecipeDescription("");
          setRecipeDoctor("");
          setSelectedPrimaryRawItems(Array(itemSections).fill(""));
          setSelectedAlternativeRawItems(Array(itemSections).fill(""));
          setShowAlternatives(Array(itemSections).fill(false));
          setItemSections(1);
        } else {
          console.error("Failed to add recipe");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Log the specific error message from the server, if available
        console.error("Server Error:", error.response.data); // Assuming the server returns error details in the response
      });
  };

  // Function to validate recipe name
  const validateRecipeName = () => {
    if (!recipeName) {
      setRecipeNameError("Please enter a recipe name.");
      return false;
    }
    setRecipeNameError("");
    return true;
  };

  // Function to validate recipe name
  const validateRecipeNote = () => {
    if (!recipeName) {
      setRecipeNoteError("Please enter a recipe name.");
      return false;
    }
    setRecipeNoteError("");
    return true;
  };

  // Function to validate recipe unit
  const validateRecipeUnit = () => {
    const anyEmptyUnit = selectedPrimaryRawItems.some((_, index) => {
      const unit = document.getElementById(`raw_item_unit_${index}`)?.value;
      return unit.trim() === "";
    });
    if (anyEmptyUnit) {
      setRecipeUnitError("Please enter a unit for all selected ingredients.");
      return false;
    }
    setRecipeUnitError("");
    return true;
  };

  // Function to validate recipe description
  const validateRecipeDescription = () => {
    if (!rawItems) {
      setRecipeIngredientError("Please enter a recipe description.");
      return false;
    }
    setRecipeIngredientError("");
    return true;
  };

  // Function to validate recipe doctor
  const validateRecipeDoctor = () => {
    if (!recipeDoctor) {
      setRecipeDoctorError("Please choose a doctor.");
      return false;
    }
    setRecipeDoctorError("");
    return true;
  };

  // Function to validate recipe ingredient
  const validateRecipeIngredient = () => {
    if (selectedPrimaryRawItems.some((item) => !item.trim())) {
      setRecipeIngredientError("Please select ingredients.");
      return false;
    }
    setRecipeIngredientError("");
    return true;
  };

  // Validation function for recipe ingredient
  const validateRecipeQuantity = () => {
    if (selectedPrimaryRawItems.some((item) => !item.trim())) {
      setRecipeQuantityError("Please Enter Quantity.");
      return false;
    }
    setRecipeQuantityError("");
    return true;
  };

  const handleRemoveItemSection = (indexToRemove) => {
    setItemSections((prev) => prev - 1);
    setSelectedPrimaryRawItems((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
    setSelectedAlternativeRawItems((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
    setShowAlternatives((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
    // Update any other state or perform additional cleanup as needed
  };
  const renderItemSections = (index) => {
    const sections = [];
    for (let i = 0; i < itemSections; i++) {
      sections.push(
        <div key={i} className="item choose section">
          <div className="box-border h-[auto] w-[900px] p-4 border-2 mb-5">
            <div>
              <div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => handleRemoveItemSection(index)}
                    className="flex items-center justify-center w-8 h-8 rounded-full bg-[#D4E7C5] text-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <label
                  htmlFor={`country-${i}`}
                  className="block text-sm font-semibold leading-6 text-gray-900 mr-4"
                >
                  Item {i + 1}
                </label>
                <div className="sm:col-span-3 flex flex-row items-center mb-3">
                  <label
                    htmlFor={`Ingredients-${i}`}
                    className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                  >
                    Select Ingredients
                  </label>
                  <div className="mt-2 flex flex-row items-center">
                    <select
                      id={`raw_item_id-${i}`}
                      name={`Ingredients-${i}`}
                      autoComplete={`Ingredients-${i}`}
                      className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={selectedPrimaryRawItems[i]}
                      onChange={(e) => handleRawItemChange(e, i, true)}
                    >
                      <option value="">Select an item</option>
                      {rawItems.map((rawItem, index) => (
                        <option key={index} value={rawItem}>
                          {rawItem}
                        </option>
                      ))}
                    </select>
                  </div>
                  {recipeIngredientError && (
                    <label className="text-red-500 ml-5">
                      {recipeIngredientError}
                    </label>
                  )}
                </div>
                <div className="sm:col-span-2 flex flex-row items-center mb-3">
                  <label
                    htmlFor={`raw_item_unit_${i}`}
                    className="block text-sm font-medium leading-6 text-gray-900 mr-4 "
                  >
                    Unit{" "}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={`raw_item_unit_${i}`}
                      id={`raw_item_unit_${i}`}
                      value={rawItemUnits[i]} // Display the selected unit
                      autoComplete={`raw_item_unit_${i}`}
                      onChange={(e) => handleRawItemChange(e, i, true)}
                      className={`pl-5 ml-[90px] px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                        recipeIngredientError ? "bg-gray-200" : ""
                      }`} // Add bg-gray-200 to visually indicate that the input field is disabled
                      disabled={recipeIngredientError} // Disable input field if there's an error
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1 flex flex-row items-center">
                  <label
                    htmlFor={`raw_item_quantity_${i}`}
                    className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                  >
                    Quantity
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name={`raw_item_quantity_${i}`}
                      id={`raw_item_quantity_${i}`} // Ensure unique id for primary raw item quantity
                      autoComplete="address-level2"
                      className=" w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ml-[60px] pl-5 px-4"
                    />
                  </div>
                  {recipeQuantityError && (
                    <label className="text-red-500 ml-10">
                      {recipeQuantityError}
                    </label>
                  )}
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    onClick={() => toggleAlternatives(i)}
                    className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Alternatives
                  </button>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "200px" }}>
              {showAlternatives[i] && (
                <div className="max-h-60 overflow-y-auto">
                  <div>
                    <div className="sm:col-span-3  items-center">
                      <div className="flex items-center mb-3">
                        <label
                          htmlFor={`AlternativeIngredients-${i}`}
                          className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                        >
                          Select Alternative Ingredients
                        </label>
                        <div className="mt-2 flex flex-row items-center">
                          <select
                            id={`alternative_raw_item_id-${i}`}
                            name={`AlternativeIngredients-${i}`}
                            autoComplete={`AlternativeIngredients-${i}`}
                            className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            value={selectedAlternativeRawItems[i]}
                            onChange={(e) => handleRawItemChange(e, i, false)}
                          >
                            <option value="">Select an alternative item</option>
                            {rawItems.map((rawItem, index) => (
                              <option key={index} value={rawItem}>
                                {rawItem}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex items-center mb-3">
                        <label
                          htmlFor={`alternative_raw_item_unit_${i}`}
                          className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                        >
                          Unit{" "}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name={`alternative_raw_item_unit_${i}`}
                            id={`alternative_raw_item_unit_${i}`}
                            autoComplete="address-level1"
                            value={altrawItemUnits[i]}
                            className={`pl-5 ml-[50px] px-4 block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                            disabled={recipeIngredientError}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2 sm:col-start-1 flex flex-row items-center">
                      <label
                        htmlFor={`alternative_raw_item_quantity_${i}`}
                        className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                      >
                        Quantity
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name={`alternative_raw_item_quantity_${i}`}
                          id={`alternative_raw_item_quantity_${i}`}
                          autoComplete="address-level2"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ml-6 pl-5 px-4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Create Recipe
      </p>
      <div className=" box-border w-full p-4 bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="ml-8">
              <div className="sm:col-span-3 flex flex-row items-center">
                <label
                  htmlFor="recipe_name"
                  className="text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Recipe name
                </label>
                <input
                  type="text"
                  name="recipe_name"
                  id="recipe_name"
                  autoComplete="recipe-name"
                  value={recipeName}
                  onChange={(e) => setRecipeName(e.target.value)}
                  className="w-[65%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                />
              </div>
              {recipeNameError && (
                <label className="text-red-500 ml-[100px]">
                  {recipeNameError}
                </label>
              )}
              <br />
              {renderItemSections()}
              <div className="mt-8">
                {hasPermission(2400) && (
                  <button
                    type="button"
                    onClick={handleAddItemSection}
                    className="rounded-md bg-[#D4E7C5] mb-5 px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add
                  </button>
                )}
              </div>
              <div className="sm:col-span-3 flex flex-row items-center">
                <label
                  htmlFor="recipe_doctor"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Choose a Doctor{" "}
                </label>
                <div className="mt-2 flex flex-row items-center">
                  <select
                    id="recipe_doctor"
                    name="recipe_doctor"
                    autoComplete="recipe-doctor"
                    value={recipeDoctor}
                    onChange={(e) => setRecipeDoctor(e.target.value)}
                    className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Doctor</option>
                    {doctors.map((doc) => (
                      <option key={doc.id} value={doc.id}>
                        {doc.id} - {doc.name}
                      </option>
                    ))}
                  </select>
                </div>
                {recipeDoctorError && (
                  <label className="text-red-500">{recipeDoctorError}</label>
                )}
              </div>
              <div className=" mt-2 flex items-center grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5">
                <div className="col-span-full p-5">
                  <label
                    htmlFor="recipe_discription"
                    className="text-sm font-medium leading-6 text-gray-900 flex flex-row items-center"
                  >
                    Note
                  </label>
                  <div className="mt-2">
                    <textarea
                      placeholder="Add a special note about recipe"
                      id="recipe_discription"
                      name="recipe_discription"
                      value={recipeDescription}
                      onChange={(e) => setRecipeDescription(e.target.value)}
                      rows={3}
                      className="block w-[100%] rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 pl-5 px-4 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {recipeNoteError && (
                    <label className="text-red-500">{recipeNoteError}</label>
                  )}
                </div>
                <div className="  gap-x-6 col-span-full sm:col-start-4 sm:col-span-3">
                  {hasPermission(2401) && (
                    <button
                      type="submit"
                      className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Submit
                    </button>
                  )}
                  {showSuccessMessage && (
                    <div className="text-green-600 text-sm mt-2">
                      Recipe Created successfully!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateRecipe;
