/** @format */

import React, { useState, useEffect } from "react";
import ViewMore from "./viewmore";
import Cookies from "js-cookie";

function Approve1Adjustment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMoreData, setViewMoreData] = useState(null);
  const userId = Cookies.get("employee_no");
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    adjustment_id: null,
    action: "", // "APPROVE" or "REJECT"
    description: "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/adjustments/pendingAdjustments/${1}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusChange = (adjustment, action) => {
    setConfirmationDialog({
      isOpen: true,
      adjustment_id: adjustment.adjustment_id,
      action: action,
      description: "",
    });
  };

  const confirmStatusChange = async () => {
    const { adjustment_id, action, description } = confirmationDialog;
    const approvedStatus = action === "APPROVED" ? "APPROVED" : "REJECTED";
    const approverId = userId;
    const approvalLevel = "1";

    const requestBody = {
      adjustment_id: adjustment_id,
      approver_id: approverId,
      approval_level: approvalLevel,
      description: description,
      action: approvedStatus,
    };

    console.log("Request Body:", requestBody); // Log the request body

    try {
      const url = `${REACT_APP_API_ENDPOINT}/v1/inventory/adjustments/approveAdjustment`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedItem = await response.json();
      console.log("Status update response:", updatedItem);

      setData((prevData) =>
        prevData.map((item) =>
          item.id === adjustment_id ? { ...item, ...updatedItem } : item
        )
      );
      fetchData(); // Refresh data to reflect the changes
      setConfirmationDialog({
        isOpen: false,
        adjustment_id: null,
        action: "",
        description: "",
      });
    } catch (err) {
      console.error("Failed to update status:", err);
      alert("Failed to update the status due to an error.");
    }
  };

  const filteredData = data.filter((item) => {});

  const handleViewMore = (adjustment) => {
    setViewMoreData(adjustment);
    setViewMoreOpen(true);
  };
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Approve One Inventory Item Adjustments
      </p>

      <div className="mt-5 ml-6 mb-[50px]"></div>

      <div className="flex justify-between items-center mt-[-50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Disposed ID"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Adjustment ID</th>
              <th className="text-primary px-4 py-2">Adjustment Date</th>
              <th className="text-primary px-4 py-2">Adjustment User</th>
              <th className="text-primary px-4 py-2">View Items</th>
              <th className="text-primary px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2 text-center">
                  Loading...
                </td>
              </tr>
            )}
            {/* Error state */}

            {/* No data state */}
            {data.length === 0 && (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
            {/* Render data */}
            {data.map((adjustment) => (
              <tr key={adjustment.adjustment_id} className="">
                <td className="bg-white px-4 py-2 text-center">
                  {adjustment.adjustment_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {adjustment.adjustment_date}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {adjustment.adjustment_user_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  <button
                    className="text-primary hover:underline"
                    onClick={() => handleViewMore(adjustment)}
                  >
                    View More
                  </button>
                </td>

                <td className="bg-white px-4 py-2 text-center">
                  <button
                    className="bg-[#5B6D49] text-white px-4 py-2 rounded-[22px] mr-2"
                    onClick={() => handleStatusChange(adjustment, "APPROVED")}
                  >
                    Approve
                  </button>
                  <button
                    className="bg-[#C8021F] text-white px-4 py-2 rounded-[22px]"
                    onClick={() => handleStatusChange(adjustment, "REJECTED")}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {viewMoreOpen && (
        <ViewMore
          onClose={() => setViewMoreOpen(false)}
          adjustmentItems={viewMoreData.inventory_adjustment_item}
        />
      )}

      {confirmationDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">
              Are you sure you want to {confirmationDialog.action.toLowerCase()}{" "}
              this item?
            </p>
            <input
              type="text"
              placeholder="Add a description"
              value={confirmationDialog.description}
              onChange={(e) =>
                setConfirmationDialog({
                  ...confirmationDialog,
                  description: e.target.value,
                })
              }
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() =>
                  setConfirmationDialog({
                    isOpen: false,
                    adjustment_id: null,
                    action: "",
                    description: "",
                  })
                }
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor:
                    confirmationDialog.action === "APPROVED"
                      ? "#5B6D49"
                      : "#C8021F",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "0.375rem", // equivalent to 'rounded' in Tailwind CSS
                }}
                onClick={confirmStatusChange}
              >
                {confirmationDialog.action === "APPROVED"
                  ? "Yes, Approve"
                  : "Yes, Reject"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Approve1Adjustment;
