/** @format */

import React, { useEffect, useState } from "react";
import "./addStores.css";
import IdGenerate from "../../../utils/id_generate";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "axios";

export default function AddStores() {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [userId, setUserId] = useState("USER-000000");
  const [subItem, setSubItem] = useState("");
  const [selectedSubItems, setSelectedSubItems] = useState([]);
  const [subItemList, setSubItemList] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [data, setData] = useState({
    store_id: IdGenerate("STORE"),
    store_name: "",
    store_description: "",
    store_update_date: "",
    store_main_category: "",
    store_sub_category: [],
    store_update_user_id: userId,
  });

  useEffect(() => {
    // Fetch main categories on component mount
    fetchMainCategories();
  }, []);

  useEffect(() => {
    // Fetch sub-items when store_item_type changes
    if (data.store_main_category) {
      fetchSubItems(data.store_main_category);
    }
  }, [data.store_main_category]);

  const fetchMainCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-category`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (
        result.item_category_names &&
        Array.isArray(result.item_category_names)
      ) {
        setMainCategories(result.item_category_names);
        console.log("main categories:", mainCategories);
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error fetching main categories:", error);
    }
  };

  const fetchSubItems = async (storeMainCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-sub-category?item_category_name=${storeMainCategory}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (
        data.item_sub_category_names &&
        Array.isArray(data.item_sub_category_names)
      ) {
        setSubItemList(
          data.item_sub_category_names.map((subItem) => ({
            store_sub_category: subItem,
          }))
        );
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching sub-items:", error);
    }
  };
  const handleAddSubItem = () => {
    if (subItem) {
      const selectedSubItem = subItemList.find(
        (item) => item.store_sub_category === subItem
      );
      if (selectedSubItem && !selectedSubItems.includes(subItem)) {
        setSelectedSubItems((prevSelectedSubItems) => [
          ...prevSelectedSubItems,
          subItem,
        ]);
        setSubItem(""); // Reset selected sub-item after adding
      }
    }
  };

  const handleSubItemChange = (event) => {
    setSubItem(event.target.value);
  };

  const handleDeleteSubItem = (index) => {
    setSelectedSubItems((prevSelectedSubItems) =>
      prevSelectedSubItems.filter((_, i) => i !== index)
    );
  };

  const SubmitHandler = async () => {
    // Ensure the selected sub-items are added as an array in the Data object
    const Data = {
      ...data,
      store_sub_category: selectedSubItems, // Use selectedSubItems directly as it is an array
      store_update_date: formattedDate, // Include the formatted date
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/store/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data), // Send the data as a JSON string
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      alert("Store Added Successfully");
      ResetHandler(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error adding store:", error);
      alert("Failed to add store");
    }
  };

  const ResetHandler = () => {
    setData({
      store_id: IdGenerate("STORE"),
      store_name: "",
      store_description: "",
      store_update_date: "",
      store_main_category: "",
      store_sub_category: [],
      store_update_user_id: userId,
    });
    setSelectedSubItems([]); // Reset the selected sub-items
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Add Stores</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter Store Details</p>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.store_id}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_id = e.target.value;
                setData(Data);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              placeholder="Enter Name"
              value={data.store_name}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_name = e.target.value;
                setData(Data);
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Description</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              placeholder="Enter Description"
              value={data.store_description}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_description = e.target.value;
                setData(Data);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Type</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036]"
              value={data.store_main_category}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_main_category = e.target.value;
                setData(Data);
              }}
            >
              <option value="" disabled>
                Select Item Type
              </option>
              {mainCategories.length > 0 ? (
                mainCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))
              ) : (
                <option>No categories available</option>
              )}
            </select>
          </div>

          <div className="w-[780px] flex items-center justify-between my-10">
            <label className="label-1">Add Sub Item Category</label>
            <label className="label-1">:</label>
            <div className="flex items-center">
              <select
                className="input-1 border border-[#00000036]"
                value={subItem}
                onChange={handleSubItemChange}
              >
                <option value="">Select Sub Item Category</option>
                {subItemList.map((subItem, index) => (
                  <option key={index} value={subItem.store_sub_category}>
                    {subItem.store_sub_category}
                  </option>
                ))}
              </select>
              <button
                onClick={handleAddSubItem}
                className="bg-[#99BC85] text-white h-[30px] w-[70px] rounded-md ml-5"
              >
                Add
              </button>
            </div>
          </div>

          <div className="w-[600px] flex flex-wrap gap-2">
            {selectedSubItems.map((item, index) => (
              <div
                key={index}
                className="flex items-center text-[12px] bg-[#E1F0DA] justify-between rounded-lg p-1"
              >
                <div>{item}</div>
                <IoMdCloseCircle
                  onClick={() => handleDeleteSubItem(index)}
                  className="text-black cursor-pointer ml-2"
                  size={20}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end gap-x-6 mb-10 px-[200px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
          onClick={SubmitHandler}
        >
          <p className="text-white">ADD</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={ResetHandler}
        >
          <p className="text-white">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
