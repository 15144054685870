import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
// import AddManualDisposeRecord from './addManualDisposeRecord'
// import  EditManualDisposeRecord from './editManualDisposeRecord'

const ManualDesposal = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableRest, setTableReset] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");
  const [sampleDetails, setSampleDetails] = useState([]); // Updated initialization

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpenf state
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  // Define state for start date and end date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [row, setRow] = useState();
  const [editOpen, setEditOpen] = useState();
  const [notification, setNotification] = useState({ message: "", type: "" });

  const rowsPerPage = 5; // Number of rows per page

  // Calculate the number of pages
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // Get the current rows for the page
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );



  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };
  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };


  // Function to handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getTableData = async () => {
    try {
      const params = {};

      if (searchTerm) {
        params.sample_id = searchTerm;
      }
      if (startDate) {
        params.start_date = startDate;
      }
      if (endDate) {
        params.end_date = endDate;
      }

      const response = await axios.get(
        `${backendUrl}/qulaityDisposals/getSampleDisposalApproval1`,
        {
          params,
        }
      );

      console.log(response.data);
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  

  // table rest
  if (tableRest == true) {
    getTableData();
    setTableReset(false);
  }

  const viewSampleAllocation = (row) => {
    console.log(row);
  };

  const handleApprovalChange = (e, dispose_id) => {
    const newStatus = e.target.value;

    // Update the status in your state or perform any necessary operations
    setSampleDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.dispose_id === dispose_id
          ? { ...detail, approvalStatus: newStatus }
          : detail
      )
    );

    updateTable(dispose_id,newStatus);

    // Additional code to handle the status change, such as API calls, etc.
  };

  const updateTable = async (dispose_id,newStatus) => {
    try {
      const response = await axios.put(
        `${backendUrl}/qualitySamples/updateSamples?dispose_id=${dispose_id}`,
        {"pproval_stage_1": newStatus}
      );

      setNotification({
        message: "Sample details Edit successfully!",
        type: "success",
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);

      setNotification({
        message: error.response.data,
        type: "error",
      });
    }
  }

  useEffect(() => {
    getTableData();
  }, [searchTerm]);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Approval 01 : Manual Disposal
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:gap-4">
          {/* Search Box with Manual Width */}
          <div className="w-full mt-6 md:w-[300px]">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-14">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Employee Number
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Type
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Name
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Tested Date
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Expiry Date
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Description
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                View More
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Approval
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRows.map((row) => (
              <tr key={row.sample_id}>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_id}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.tested_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.Expiry_Date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.disposal_remark}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  <button
                    className="p-2 text-blue-500 rounded hover:bg-blue-100"
                    onClick={() => viewSampleAllocation(row)}
                  >
                    Click here
                  </button>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  <select
                    className="p-2 border border-gray-300 rounded"
                    value={row.approvalStatus}
                    onChange={(e) => handleApprovalChange(e, row.


                      dispose_id
                      



                    )}
                  >
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 text-sm rounded ${
              currentPage === i + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Add Sample Allocation Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddManualDisposeRecord
            togglePopup={togglePopup}
            resetTable={setTableReset}
            />
          </div>
        </div>
      )} */}

      {/* Edit Sample Allocation Process Popup */}
      {/* {editOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
          <EditManualDisposeRecord togglePopup={closeEditModal}/>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ManualDesposal;
