import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import TableTenderUpdateOfBidders from "./table";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import Upload_sample_Reports from "./uploadSampleReports";
import axios from "axios";
import IdGenerate from "../../../../utils/id_generate";
import usePermissions from "../../../../components/permissions/permission";

const Sample_Testing = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);
  const [imagePreviewfourth, setImagePreviewfouth] = useState(null);
  const [imagefourth, setImagefourth] = useState(null);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSupplierTerm, setSearchSupplierTerm] = useState("");
  const [tenders, setTenders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [selectedSupplier, setSelectedSupplierTender] = useState([]);
  const [appointingDate, setAppointingDate] = useState(null);
  const [tenderId, setTenderId] = useState("");
  const [tenderName, setTenderName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [SampleNumber, setSampleNumber] = useState(IdGenerate("SAM"));
  const [drugName, setDrugName] = useState("");
  const [symbolNumber, setSymbolNumber] = useState("");
  const [dateOfSubmition, setDateOfSubmition] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [searchSup, setSearchSup] = useState("");
  const [sampleData, setSampleData] = useState([]);
  const [internal_auditor_employee_id, set_internal_auditor_employee_id] =
    useState("");
  const [certified_1_employee_id, set_certified_1_employee_id] = useState("");
  const [certified_2_employee_id, set_certified_2_employee_id] = useState("");
  const [certified_3_employee_id, set_certified_3_employee_id] = useState("");
  const [certified_1_employee_role, set_certified_1_employee_role] =
    useState("");
  const [certified_2_employee_role, set_certified_2_employee_role] =
    useState("");
  const [certified_3_employee_role, set_certified_3_employee_role] =
    useState("");
  const [
    certified_1_employee_designation,
    set_certified_1_employee_designation,
  ] = useState("");
  const [
    certified_2_employee_designation,
    set_certified_2_employee_designation,
  ] = useState("");
  const [
    certified_3_employee_designation,
    set_certified_3_employee_designation,
  ] = useState("");

  const openDialog = () => {
    setShowDialogBox(true);
  };

  const closeDialog = () => {
    setShowDialogBox(false);
  };

  const SampleView = () => {
    navigate("/committess/sampleTestingView");
  };

  const SampleDocumentView = () => {
    navigate("/committess/sampleTestingDocument");
  };

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Second Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fourth Signature Box
  const handleDragOverfourth = (e) => {
    e.preventDefault();
  };

  const handleDropfourth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagefourth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfouth(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/search/tender/${query}`
        );
        setTenders(response.data);

        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleSearchContractChange = async (event) => {
    const query = event.target.value;
    setSearchSupplierTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
        );
        setCustomers(response.data);
        setIsCustomerDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCustomers([]);
      }
    } else {
      setCustomers([]);
      setIsCustomerDropdownOpen(false);
    }
  };
  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);

    //Assign values to the fetching input field.
    const tenderDateParts = result.deadline_date.split("/");
    const tenderDate = new Date(
      `${tenderDateParts[2]}/${tenderDateParts[1]}/${tenderDateParts[0]}`
    );
    setAppointingDate(tenderDate);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
    // setSupplierName(result.contract_id);
  };

  const handleContractOptionClick = (result) => {
    setSearchSupplierTerm(result.supplier_name);
    setSelectedSupplierTender(result);
    setIsCustomerDropdownOpen(false);
    setSupplierName(result.supplier_name);
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  };

  const handleSupplierReset = () => {
    setSearchSupplierTerm("");
    setSelectedSupplierTender([]);
    setIsCustomerDropdownOpen(false);
    setIsDropdownOpen(false);
  };

  const handleAddSample = () => {
    const date = dateOfSubmition.toLocaleDateString("en-GB");
    const newSample = {
      SampleNumber,
      drugName,
      symbolNumber,
      date,
      status,
      searchSupplierTerm,
    };

    setSampleData([...sampleData, newSample]); // Add new sample to the array

    // Clear input fields after adding
    setSampleNumber(IdGenerate("SAM"));
    setDrugName("");
    setSymbolNumber("");
    setDateOfSubmition(new Date()); // Reset date to current
    setStatus("");
    setSupplierName("");
  };

  const handleSubmit = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("tender_id", tenderId);
      formData.append(
        "internal_auditor_employee_id",
        internal_auditor_employee_id
      );
      formData.append(
        "internal_auditor_update_date",
        date.toLocaleDateString("en-GB")
      );

      // Fist Employee
      formData.append("certified_1_employee_id", certified_1_employee_id);
      formData.append("certified_1_employee_role", certified_1_employee_role);
      formData.append(
        "certified_1_employee_designation",
        certified_1_employee_designation
      );
      formData.append(
        "certified_1_employee_update_date",
        date.toLocaleDateString("en-GB")
      );

      // Second Employee
      formData.append("certified_2_employee_id", certified_2_employee_id);
      formData.append("certified_2_employee_role", certified_2_employee_role);
      formData.append(
        "certified_2_employee_designation",
        certified_2_employee_designation
      );
      formData.append(
        "certified_2_employee_update_date",
        date.toLocaleDateString("en-GB")
      );

      // Thired Employee
      formData.append("certified_3_employee_id", certified_1_employee_id);
      formData.append("certified_3_employee_role", certified_1_employee_role);
      formData.append(
        "certified_3_employee_designation",
        certified_3_employee_designation
      );
      formData.append(
        "certified_3_employee_update_date",
        date.toLocaleDateString("en-GB")
      );

      // Append members_in_attendance array
      sampleData.forEach((sampledata, index) => {
        formData.append(
          `samples[${index}][sample_no]`,
          sampledata.SampleNumber
        );
        formData.append(`samples[${index}][drug_name]`, sampledata.drugName);
        formData.append(
          `samples[${index}][symbol_no]`,
          sampledata.symbolNumber
        );
        formData.append(
          `samples[${index}][date_of_submission]`,
          sampledata.date
        );
        formData.append(
          `samples[${index}][bidder_id]`,
          sampledata.searchSupplierTerm
        );
        formData.append(`samples[${index}][status]`, sampledata.status);
        formData.append(`samples[${index}][updated_user_id]`, "EMP-0004");
        formData.append(
          `samples[${index}][updated_date]`,
          date.toLocaleDateString("en-GB")
        );
      });

      formData.append("internal_auditor_img", image); // Append file directly
      formData.append("certified_1_img", imagesecond);
      formData.append("certified_2_img", imagethired);
      formData.append("certified_3_img", imagefourth);

      formData.append("user_id", "EMP-00001");
      formData.append("report_date", date.toLocaleDateString("en-GB"));

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/sample_testing/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response

      console.log("Response:", response.data);
      alert("Sample Testing Committee Report successfully added");
      resetSubmit();
      // handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to addSample Testing Committee Report");
    }
  };

  const resetSubmit = () => {
    setTenderId("");
    setSampleData([]);
    setSampleNumber(IdGenerate("SAM"));
    set_internal_auditor_employee_id("");
    set_certified_1_employee_id("");
    set_certified_2_employee_id("");
    set_certified_3_employee_id("");
    set_certified_1_employee_role("");
    set_certified_2_employee_role("");
    set_certified_3_employee_role("");
    set_certified_1_employee_designation("");
    set_certified_2_employee_designation("");
    set_certified_3_employee_designation("");
    setImagePreview(null);
    setImage(null);
    setImagePreviewsecond(null);
    setImagesecond(null);
    setImagePreviewthired(null);
    setImagethired(null);
    setImagePreviewfouth(null);
    setImagefourth(null);
  };

  console.log("Set Sample data:", sampleData);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Sample Testing Committee
      </p>

      <div className="grid grid-cols-2 mt-4">
        {hasPermission(2661) && (
          <div className="bg-[#F3F8FF] rounded-[32px] p-4 shadow-md flex flex-col justify-between card w-[80%] ml-[10%]">
            <div className=" flex">
              <div className="">
                <h2 className="text-[42px] text-[#797C80] font-semibold">
                  Sample
                </h2>
                <h3 className="text-[42px] text-[#797C80] font-semibold">
                  Report Document
                </h3>
              </div>

              <div className="grid grid-cols-1 items-center ml-4 ">
                <div>
                  {hasPermission(10050) && (
                    <button
                      className="bg-[#D1D1D1] w-[133px] font-bold py-2 px-4 rounded-[22px] text-[#5B6D49]"
                      onClick={SampleDocumentView}
                    >
                      View
                    </button>
                  )}
                </div>
                <div>
                  {hasPermission(10051) && (
                    <button
                      className="bg-primary w-[133px]  text-white font-bold py-2 px-4 rounded-[22px]"
                      onClick={openDialog}
                    >
                      Upload
                    </button>
                  )}
                </div>
                {showDialogBox && (
                  <Upload_sample_Reports
                    onClose={closeDialog}
                    data={tenderId}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {/* Second Card */}
        {hasPermission(2660) && (
          <div className="bg-[#F3F8FF] rounded-[32px] p-4 shadow-md flex flex-col justify-between card w-[70%] ml-[10%]">
            <div className="flex items-center">
              <div className="">
                <h2 className="text-[42px] text-[#797C80] font-semibold">
                  Sample
                </h2>

                <h2 className="text-[42px] text-[#797C80] font-semibold mb-1">
                  Reports
                </h2>
              </div>
              <button
                className="bg-primary w-[30%] ml-auto text-white font-bold py-2 px-4 rounded-[22px] mt-[12%]"
                onClick={SampleView}
              >
                View
              </button>
            </div>
            {showDialogBox && (
              <Upload_sample_Reports onClose={closeDialog} data={tenderId} />
            )}
          </div>
        )}

        {/* Serach Box */}
        <form className="form relative mt-4 ml-[10%]">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button
            type="button"
            className="btn btn-primary text-[#797C80] hover:bg-gray-100"
            onClick={handleReset}
          >
            RESET
          </button>
          <div className="mt-4 relative">
            {isDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                {tenders.length > 0 ? (
                  tenders.map((result) => (
                    <li
                      key={result._id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      value={selectedTender}
                      onClick={() => handleOptionClick(result)}
                    >
                      {result.tender_id}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No results found</li>
                )}
              </ul>
            )}
          </div>
        </form>
      </div>
      {/* Flex container for cards */}
      <div className="flex justify-between mt-10 mx-6">
        <div className="bg-[#F3F8FF] rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] ml-10 w-[90%] h-[20%]">
          <div className="grid grid-cols-4 gap-6 ">
            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Sample NO:
              </label>
            </div>
            <div>
              <input
                type="text"
                value={SampleNumber}
                onChange={(e) => setSampleNumber(e.target.value)}
                disabled
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Drug Name:
              </label>
            </div>
            <div>
              <input
                type="text"
                placeholder="Drug Name"
                value={drugName}
                onChange={(e) => setDrugName(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Symbol Number:
              </label>
            </div>
            <div>
              <input
                placeholder="Symbol Number"
                value={symbolNumber}
                onChange={(e) => setSymbolNumber(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Date of Submission:
              </label>
            </div>
            <div>
              <DatePicker
                selected={dateOfSubmition}
                onChange={(date) => setDateOfSubmition(date)}
                className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF]"
                placeholderText="dd-mm-yyyy"
                disabled
              />
            </div>

            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Bidder/Supplier Name:
              </label>
            </div>
            <div>
              <input
                type=" text"
                value={searchSupplierTerm} // Bind the value to searchSupplierTerm
                onChange={handleSearchContractChange} // Handle change to update searchSupplierTerm
                placeholder="Search Supplier Name..."
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 text-xl font-bold"
              />
              <button
                type="button"
                className="btn btn-primary text-[#797C80] hover:bg-gray-100"
                onClick={handleSupplierReset}
              >
                RESET
              </button>
              <div className="mt-4 relative">
                {isCustomerDropdownOpen && (
                  <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                    {customers.length > 0 ? (
                      customers.map((result) => (
                        <li
                          key={result._id}
                          className="p-2 hover:bg-gray-100 cursor-pointer text-xl font-bold"
                          value={selectedSupplier}
                          onClick={() => handleContractOptionClick(result)}
                        >
                          {result.supplier_name}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500 text-xl font-bold">
                        No results found
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>

            <div className="">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Status:
              </label>
            </div>
            <div>
              <select
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl "
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" className=" text-gray-400">
                  Suitable/Not Suiteable
                </option>
                <option value="Suitable">Suitable</option>
                <option value="Not Suiteable">Not Suiteable</option>

                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-end mr-[10%] mt-6">
        {hasPermission(2662) && (
          <button
            className=" bg-[#5B6D49] w-16 h-8 text-white font-bold rounded-[22px]"
            onClick={handleAddSample}
          >
            Add
          </button>
        )}
      </div>
      <div className="ml-6">
        <div className="mt-4 ml-6">
          <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
            Tender Updates of Bidders
          </p>
        </div>
        <TableTenderUpdateOfBidders data={sampleData} />
      </div>
      {/* Signarute Image Uploader */}

      <div className="  grid grid-cols-4 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Internal Auditor
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOversecond}
          onDrop={handleDropsecond}
        >
          {imagePreviewsecond ? (
            <img
              src={imagePreviewsecond}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Thiered Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverthired}
          onDrop={handleDropthired}
        >
          {imagePreviewthired ? (
            <img
              src={imagePreviewthired}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Fourth Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverfourth}
          onDrop={handleDropfourth}
        >
          {imagePreviewfourth ? (
            <img
              src={imagePreviewfourth}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="grid grid-flow-row-dense grid-cols-8 grid-rows-3 gap-2 text-[#8691AB] font-bold ... ">
          <div className="row-span-3  flex justify-end ">
            <label>Name:</label>
          </div>
          <div className="row-span-3">
            <input
              className=" bg-white rounded-[23px] w-[90%]"
              value={internal_auditor_employee_id}
              onChange={(e) => set_internal_auditor_employee_id(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_1_employee_id}
              onChange={(e) => set_certified_1_employee_id(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_2_employee_id}
              onChange={(e) => set_certified_2_employee_id(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_3_employee_id}
              onChange={(e) => set_certified_3_employee_id(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_1_employee_role}
              onChange={(e) => set_certified_1_employee_role(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_2_employee_role}
              onChange={(e) => set_certified_2_employee_role(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_3_employee_role}
              onChange={(e) => set_certified_3_employee_role(e.target.value)}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_1_employee_designation}
              onChange={(e) =>
                set_certified_1_employee_designation(e.target.value)
              }
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_2_employee_designation}
              onChange={(e) =>
                set_certified_2_employee_designation(e.target.value)
              }
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={certified_3_employee_designation}
              onChange={(e) =>
                set_certified_3_employee_designation(e.target.value)
              }
            ></input>
          </div>
        </div>
      </div>
      <div className=" flex justify-end mr-4  text-[16px] font-bold mt-4 mb-4">
        {hasPermission(2663) && (
          <button
            className="text-[#5B6D49] rounded-[16px] mr-6"
            onClick={handleSubmit}
          >
            Save & Send
          </button>
        )}
      </div>
    </div>
  );
};

export default Sample_Testing;
