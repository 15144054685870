/** @format */

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";
import Printer from "../../Assets/icons/print 1.png";
import axios from "axios";
import Cookies from "js-cookie";
import IdGenerate from "../../utils/id_generate";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function ApprovedItemReqFromBranch() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  // const [userId, setUserId] = useState("USER-000000");
  const userId = Cookies.get("employee_no");
  const [userName, setUserName] = useState("");
  const location = useLocation();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  const [releasedItem, setReleasedItem] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [subTotal, setSubTotal] = useState(0);
  const branchId = Cookies.get("branch");

  console.log("selectedRecord", selectedRecord);
  console.log("ReleasedRecord", releasedItem);

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    inventory_request_id: selectedRecord.inventory_request_id,
    inventory_request_item_qty: selectedRecord.inventory_request_item_qty,
    inventory_request_by: selectedRecord.inventory_request_by,
  });

  useEffect(() => {
    const fetchTotalCostOfReleasedItems = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/getTotalCostOfReleasedRequest/${selectedRecord.inventory_request_id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setReleasedItem(data || []);
        console.log("released items5:", releasedItem);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    if (selectedRecord.inventory_request_id) {
      fetchTotalCostOfReleasedItems();
      console.log("Inventory request ID:", selectedRecord.inventory_request_id);
    }
  }, [selectedRecord.inventory_request_id]);

  function transformData(data) {
    const formattedDate = new Date().toISOString(); // Adjust as needed

    return {
      inventory_request_id: data[0]?.inventory_request_id || "",
      inventory_received_date: formattedDate,
      inventory_received_user_id: "USER-06247866", // Replace with actual user ID
      invoice_total: data.reduce((total, item) => {
        return (
          total +
          item.batches.reduce((batchTotal, batch) => {
            return batchTotal + batch.added_qty * batch.batch_price;
          }, 0)
        );
      }, 0),
      inventory_received_status: "RECEIVED",
      items:
        data[0]?.batches.map((batch) => ({
          item_id: batch.item_id,
          item_name: batch.item_name,
          inventory_request_item_qty: batch.added_qty, // Assuming request qty is same as added qty
          inventory_release_item_qty: batch.added_qty, // Assuming release qty is same as added qty
          batches: [
            {
              added_qty: batch.added_qty,
              batch_price: batch.batch_price,
              item_measure_unit: batch.item_measure_unit,
              item_total: batch.added_qty * batch.batch_price,
              inventory_batch_id: batch.inventory_batch_id,
              inventory_release_item_description: "", // Add description if available
            },
          ],
        })) || [],
    };
  }

  const SubmitHandler = async () => {
    // Transform the data
    const transformedData = transformData(releasedItem);
    console.log("ReleasedItem:", releasedItem);
    console.log("Transformed Data:", transformedData);

    try {
      const response = await fetch(
        // `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/addBranchInventoryRequestLog`,
        "http://localhost:8089/v1/inventory/inventoryRequest/addBranchInventoryRequestLog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );

      if (response.ok) {
        const resData = await response.json();
        console.log(resData);
        alert("Inventory Request Received Successfully");
        handlePrint();
        window.location.reload();
      } else {
        const errorData = await response.json();
        if (response.status === 409) {
          alert("Inventory Already Exist");
        } else if (response.status === 400) {
          alert("Inventory Not Added");
        } else if (response.status === 500) {
          alert("Internal Server Error");
        } else if (response.status === 404) {
          alert("Inventory Not Found");
        } else if (response.status === 403) {
          alert("Forbidden");
        } else if (response.status === 401) {
          alert("Unauthorized");
        } else {
          alert("Error: " + errorData.message);
        }
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
    let total = 0;

    // Loop through each batch in the releasedItem array
    releasedItem.forEach((item) => {
      // Calculate the total for each batch and add it to the subtotal
      item.batches.forEach((batch) => {
        total += batch.added_qty * batch.batch_price;
      });
    });

    setSubTotal(total);
    console.log("Subtotal: ", total);
  }, [releasedItem]);

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Approved Branch Item Request</p>
      <div>
        <div className="flex items-center ">
          <p className="label-1">Item Released Id</p>
          <p className="label-1">:</p>
          <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#F3F8FF] ml-10 border border-[#00000036] px-7 flex items-center ">
            {selectedRecord.inventory_release_id}
          </p>
        </div>
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Request Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_date}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Requested User :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_user_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Released Order Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_release_date}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Released User :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_release_user_id}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Request Order Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Release Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Price</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Units</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Total</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div>
            {releasedItem[0]?.batches.map((batch, index) => {
              // Find the corresponding item in selectedRecord.items
              const correspondingItem = selectedRecord.items.find(
                (item) => item.item_id === batch.item_id
              );
              const Total = batch.added_qty * batch.batch_price;
              return (
                <div
                  key={index}
                  className="w-full flex items-start justify-around my-3"
                >
                  <p className="w-10 p1 text-[#656565] text-center">
                    {index + 1}
                  </p>
                  <p className="w-[170px] p1 text-[#656565] text-center">
                    {batch.item_id}
                  </p>
                  <p className="w-[350px] p1 text-[#656565] text-center">
                    {batch.item_name}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {correspondingItem
                      ? correspondingItem.inventory_release_item_qty
                      : "N/A"}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.added_qty}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.batch_price}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.item_measure_unit}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {Total.toFixed(2)}
                  </p>
                  <p className="w-[170px] p1 text-[#656565] text-center">
                    <textarea
                      className="w-[150px] border border-[#656565] my-2 rounded-2xl"
                      value={batch.inventory_release_item_description || ""}
                      onChange={(e) => {
                        const updatedReleasedItem = [...releasedItem];
                        updatedReleasedItem[0].batches[
                          index
                        ].inventory_release_item_description = e.target.value;
                        setReleasedItem(updatedReleasedItem);
                      }}
                    />
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[585px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">Branch Name:</p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_by}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Sub Total</p>
              <p className="p1 text-[#656565]">{subTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Reject
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
          // onClick={SubmitHandler}
        >
          Received
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
