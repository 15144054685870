/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DialogBox from "../Pending Purchase Request/newPurchaseDialogBox";
import usePermissions from "../../../../components/permissions/permission";

const PRSubjectNav = () => {
  const { hasPermission } = usePermissions();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogStatus, setDialogStatus] = useState("");
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  // Open dialog and set status
  const openDialog = (status) => {
    setDialogStatus(status);
    setShowDialog(true);
  };

  // Close dialog
  const closeDialog = () => {
    setShowDialog(false);
    setDialogStatus("");
  };

  const fetchSubjects = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();

      // Filter the data to only include items with item_category_name = "RAW"
      const filteredData = data.filter(
        (item) => item.item_category_name === "RAW"
      );

      setItemCategoryData(filteredData);
      console.log("Filtered Items:", filteredData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Purchase Requests for Subjects
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6">
        {/* Map over itemCategoryData to render dynamic cards */}
        {itemCategoryData.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card"
          >
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                {item.item_category_name} - {item.item_sub_category_name}
              </h2>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                {hasPermission(1800) && (
                  <button
                    onClick={() => openDialog(item.item_sub_category_name)}
                    className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}

        {/* DialogBox Component */}
        {showDialog && (
          <DialogBox onClose={closeDialog} status={dialogStatus} />
        )}
      </div>
    </div>
  );
};

export default PRSubjectNav;
