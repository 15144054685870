/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import { toWords } from "number-to-words";

const Partial_Purchase_Order_Create = () => {
  const [issueDate, setIssueDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);
  const [totalAmountInWords, setTotalAmountInWords] = useState("");
  const [referenceNo, setReferenceNO] = useState("");
  const location = useLocation();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.pr_items || []);
  console.log("selelcted rec", selectedRecord);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  // Define a function to fetch Procument item data
  const fetchProItemData = async () => {
    try {
      // Extract item IDs from procurement_items
      const itemIds = selectedRecord.pr_items.map((item) => item.item_id);

      // Fetch data for each item ID
      const fetchPromises = itemIds.map((itemId) =>
        fetch(`${REACT_APP_API_ENDPOINT}/v1/inventory/item/item/${itemId}`)
      );

      // Await all fetches
      const responses = await Promise.all(fetchPromises);

      // Parse all responses
      const dataPromises = responses.map((response) => response.json());
      const dataArray = await Promise.all(dataPromises);

      // Prepare table data based on fetched data
      const newTableData = dataArray.map((itemData, index) => {
        const procurementItem = selectedRecord.pr_items[index];
        return {
          item_id: itemData.item_id,
          item_name: itemData.item_name,
          item_description: itemData.item_description,
          item_measure_unit: itemData.item_measure_unit,
          item_category_name: itemData.item_category_name,
          item_sub_category_name: itemData.item_sub_category_name,
          total_shadow_qty: itemData.total_shadow_qty,
          item_qty: procurementItem.item_qty || 0, // Use item_qty from procurement_items if available
          item_received: procurementItem.item_received || 0,
          item_remainingQty:
            procurementItem.item_qty - procurementItem.qty_to_request,
          item_unit_cost: procurementItem.item_unit_cost || 0,
          item_line_amount: procurementItem.item_line_amount || 0,
          item_qty_to_receive: procurementItem.item_qty_to_receive || 0,
          item_qty_to_received: procurementItem.qty_to_request || 0,
          quantity_to_received: procurementItem.quantity_to_received || 0,
        };
      });

      // Update tableData state
      setTableData(newTableData);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    if ((selectedRecord.pr_items || []).length > 0) {
      fetchProItemData();
    }
  }, [selectedRecord.pr_items]);

  useEffect(() => {
    const numericAmount = parseFloat(total);
    if (!isNaN(numericAmount) && isFinite(numericAmount)) {
      setTotalAmountInWords(convertAmountToWords(numericAmount));
    } else {
      setTotalAmountInWords("");
    }
  }, [total]);

  useEffect(() => {
    let total = 0;

    // Loop through each batch in the releasedItem array
    tableData.forEach((item) => {
      // Calculate the total for each batch and add it to the subtotal
      total += item.item_qty_to_receive * item.item_unit_cost;
    });

    setSubTotal(total);
    console.log("Subtotal: ", total);
  }, [tableData]);

  useEffect(() => {
    // Parse totalDiscount and totalVAT as decimal numbers
    const discount = parseFloat(totalDiscount) || 0;
    const vat = parseFloat(totalVAT) || 0;

    // Calculate the total using parsed values
    const calculatedTotal = subTotal - discount + vat;
    setTotal(calculatedTotal);
    console.log("Total: ", calculatedTotal);
  }, [subTotal, totalDiscount, totalVAT]);

  // Function to convert total amount to words
  const convertAmountToWords = (amount) => {
    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 100); // Get the cents part

    const integerPartInWords = toWords(integerPart);
    let decimalPartInWords = "";

    if (decimalPart > 0) {
      decimalPartInWords = toWords(decimalPart);
      return `${integerPartInWords} rupees and ${decimalPartInWords} cents`;
    } else {
      return `${integerPartInWords} rupees`;
    }
  };

  // Fetch suppliers based on the search term
  const CustomerSearchHandler = async (inputValue) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${inputValue}`
      );
      const data = await response.json();

      // Map the response to the format required by react-select
      return data.map((supplier) => ({
        value: supplier.supplier_id,
        label: `${supplier.supplier_id} - ${supplier.supplier_name}`,
        supplier_name: supplier.supplier_name,
        supplier_address: supplier.supplier_address,
      }));
    } catch (error) {
      console.error("Error fetching suppliers:", error);
      return [];
    }
  };

  // Handle the selection of a supplier
  const CustomerSelectHandler = (selectedOption) => {
    setSelectedSupplier(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("supplier_id", selectedRecord.supplier_id);
      formData.append("reference_no", referenceNo);
      formData.append("po_issue_date", issueDate.toLocaleDateString("en-GB"));
      formData.append(
        "delivery_date",
        deliveryDate.toLocaleDateString("en-GB")
      );
      formData.append("address", selectedRecord.supplier_address);
      formData.append("total_order", subTotal);
      formData.append("total_discount", totalDiscount);
      formData.append("total_vat", totalVAT);
      formData.append("total", total);
      formData.append("amount_in_words", totalAmountInWords);

      // Extract item_id and item_qty from tableData and add it to the formData
      const po_items = tableData.map((item) => ({
        item_id: item.item_id,
        item_qty: item.item_qty_to_receive,
        rate: item.item_unit_cost,
        amount: item.item_line_amount,
      }));
      formData.append("po_items", JSON.stringify(po_items));

      // Add files to the formData
      formData.append("prepared_img", image); // Append file directly
      formData.append("prepared_user_id", userId);
      formData.append("prepared_date", formattedDate);
      formData.append("checked_img", imagesecond);
      formData.append("checked_user_id", userId);
      formData.append("checked_date", formattedDate);
      formData.append("approved_img", imagethired);
      formData.append("approved_user_id", userId);
      formData.append("approved_date", formattedDate);
      formData.append("po_status", "ACTIVE");
      formData.append("pr_id", selectedRecord.pr_id);

      // Inspect the FormData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchase_order/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the responsereqDate
      console.log("Response:", response.data);
      alert("Purchase Request successfully added");
      handleReset();
      navigate(-1);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    setIssueDate("");
    setDeliveryDate("");
    setTableData([]);
    setImagePreview(null);
    setImagePreviewsecond(null);
    setImagePreviewthired(null);
    setTotalAmountInWords("");
    setReferenceNO("");
    setSelectedSupplier("");
    setSubTotal(0);
    setTotalDiscount(0);
    setTotalVAT(0);
    setTotal(0);
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      border: state.isFocused ? "none" : "none",
      backgroundColor: "#ffffff", // Match the background of the input
      borderRadius: "100px", // Match the border radius of the input
      borderColor: state.isFocused ? "#00000036" : "#00000036", // Match the border color
      height: "50px",
      width: "90%",
      marginLeft: "30px",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Purchase Order Form
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Create Purchase Order</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">PR No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.pr_id}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Title</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.title}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.supplier_id}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.supplier_name || ""}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier Address</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.supplier_address || ""}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Reference No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={referenceNo}
              onChange={(e) => setReferenceNO(e.target.value)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Issue Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Issue Date"
              selected={issueDate}
              onChange={(date) => setIssueDate(date)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Delivery Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Delivery Date"
              selected={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Purchase Order Item Details</p>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[300px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center "> Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Sub Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Measure Unit
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Request Quantity
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Received</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Remaining Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Unit Cost</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Line Amount</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Qty to Receive
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Qty to Received
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[300px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_sub_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_measure_unit}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_qty}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.quantity_to_received}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_remainingQty}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        className="w-full border border-[#797979] rounded-[40px] px-[10px]"
                        value={item.item_unit_cost}
                        onChange={(e) => {
                          let value = e.target.value;

                          let temp = [...tableData];
                          temp[index].item_unit_cost = value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_line_amount}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        value={item.item_qty_to_receive}
                        className={
                          item.item_remainingQty < item.item_qty_to_receive
                            ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                            : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];

                          // Parse the input as a float to ensure it's a decimal number
                          temp[index].item_qty_to_receive = parseFloat(
                            e.target.value
                          );

                          // Recalculate the item_line_amount using the parsed value
                          temp[index].item_line_amount =
                            temp[index].item_qty_to_receive *
                            temp[index].item_unit_cost;

                          // Update the state
                          setTableData(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_qty_to_received}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end px-16 pt-3">
        <div className="w-[700px] h-full  flex flex-col gap-y-2">
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Sub Total</p>
              <p className="p1 text-[#656565]">{subTotal.toFixed(2)}</p>
            </div>
          </div>
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Total Discount</p>
              <p className="p1 text-[#656565]">
                <input
                  type="number"
                  step="0.01" // Allows decimal input
                  className="w-full border border-[#797979] rounded-[40px] px-[10px] text-right"
                  value={totalDiscount}
                  onChange={(e) => setTotalDiscount(parseFloat(e.target.value))}
                />
              </p>
            </div>
          </div>
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Total VAT</p>
              <p className="p1 text-[#656565]">
                <input
                  type="number"
                  step="0.01" // Allows decimal input
                  className="w-full border border-[#797979] rounded-[40px] px-[10px] text-right"
                  value={totalVAT}
                  onChange={(e) => setTotalVAT(parseFloat(e.target.value))}
                />
              </p>
            </div>
          </div>
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Total</p>
              <p className="p1 text-[#656565]">{total.toFixed(2)}</p>
            </div>
          </div>
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Amount In Words</p>
              <p className="p1 text-[#656565]">{totalAmountInWords}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="  grid grid-cols-3 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Ordered By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOversecond}
          onDrop={handleDropsecond}
        >
          {imagePreviewsecond ? (
            <img
              src={imagePreviewsecond}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Approved By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Thiered Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverthired}
          onDrop={handleDropthired}
        >
          {imagePreviewthired ? (
            <img
              src={imagePreviewthired}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Prepared By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default Partial_Purchase_Order_Create;
