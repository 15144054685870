/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../components/permissions/permission";
import { Link, useNavigate } from "react-router-dom";

const View_Store = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedStoreItemType, setSelectedStoreItemType] =
    useState("Select Store Name");
  const [subItemType, setSubItemType] = useState("");
  const [mainCategories, setMainCategories] = useState([]);

  const fetchMainCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-category`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (
        result.item_category_names &&
        Array.isArray(result.item_category_names)
      ) {
        setMainCategories(result.item_category_names);
        console.log("main categories:", mainCategories);
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error fetching main categories:", error);
    }
  };

  useEffect(() => {
    // Fetch main categories on component mount
    fetchMainCategories();
  }, []);

  // Define a function to fetch data
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/store`
      );
      const data = await response.json();
      setStoreData(data);
      console.log("stores :", data);
    } catch (error) {
      console.error("Error fetching exam data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const HandleOnClick = (store) => {
    // Navigate to the new page and pass the object
    navigate("/stores/update", {
      state: { selectedRecord: store },
    });
  };

  const filteredStoreData = storeData.filter((stores) => {
    const matchesStoreName =
      selectedStoreItemType === "Select Store Name" ||
      stores.store_name === selectedStoreItemType;
    const matchesSubType =
      subItemType === "" ||
      stores.store_sub_category.some((subCategory) =>
        subCategory.toLowerCase().includes(subItemType.toLowerCase())
      );
    return matchesStoreName && matchesSubType;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredStoreData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          View Stores
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="mt-3">
                <div className="flex flex-col">
                  <label className="text-xl mb-2 font-bold text-[#797C80] font-sans"></label>
                  <select
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    value={selectedStoreItemType}
                    onChange={(e) => setSelectedStoreItemType(e.target.value)}
                  >
                    <option className="font-sans-bold">
                      Select Store Name
                    </option>
                    {storeData.length > 0 ? (
                      storeData.map((store, index) => (
                        <option key={index} value={store.store_name}>
                          {store.store_name}
                        </option>
                      ))
                    ) : (
                      <option>No stores available</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                <form className="form relative">
                  <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                    <svg
                      className="w-5 h-5 text-gray-700"
                      aria-labelledby="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height="16"
                      width="17"
                    >
                      <path
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        stroke-width="1.333"
                        stroke="currentColor"
                        d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      ></path>
                    </svg>
                  </button>
                  <input
                    type="text"
                    required=""
                    placeholder="Sub Item Type"
                    value={subItemType}
                    onChange={(e) => setSubItemType(e.target.value)}
                    className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary justify-center text-[15px] font-bold py-4">
                    Store ID
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Store Name
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Store Item Main Category
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Store Item Sub Category
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Store Description
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Store Status
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((store) => (
                  <tr key={store._id} className="bg-white text-center">
                    <td className="py-4">{store.store_id}</td>
                    <td className="py-4">{store.store_name}</td>
                    <td className="py-4">{store.store_main_category}</td>
                    <td className="py-4">
                      {store.store_sub_category.join(" / ")}
                    </td>
                    <td className="py-4">{store.store_description}</td>
                    <td className="py-4">{store.store_status}</td>
                    <td className="py-4">
                      {hasPermission(1700) && (
                        <button
                          onClick={() => HandleOnClick(store)}
                          className="bg-primary rounded-[10px] p-2 text-white"
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex justify-center mt-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrPrevious className="w-3 h-3" />
            </button>
            {Array.from({
              length: Math.ceil(filteredStoreData.length / itemsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`${
                  currentPage === index + 1
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredStoreData.length / itemsPerPage)
              }
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrNext className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_Store;
