import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FiFile } from "react-icons/fi";

const TableTenderUpdateOfBidders = ({ data }) => {
  console.log("table Data:", data);
  const navigate = useNavigate();

  const [tenderViewShowDialog, setTenderViewShowDialog] = useState(false);

  const openTenderViewDialog = () => {
    setTenderViewShowDialog(true);
  };

  const closeTenderViewDialog = () => {
    setTenderViewShowDialog(false);
  };

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[96%] ml-10 max-h-[100%] overflow-y-auto mb-8 mt-6">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Sample No</th>
            <th className="px-6 py-6 ">Drug Name</th>
            <th className="px-6 py-6 ">Symbol number</th>
            <th className="px-6 py-6 ">Date of Submission</th>
            <th className="px-6 py-6 ">
              Bidder/Supplier <br />
              Name
            </th>
            <th className="px-6 py-6 ">Status</th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.SampleNumber}</td>
              <td className="px-6 py-2">{item.drugName}</td>
              <td className="px-6 py-2">{item.symbolNumber}</td>
              <td className="px-6 py-2">{item.date}</td>
              <td className="px-6 py-2">{item.searchSupplierTerm}</td>
              <td className="px-6 py-2">{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableTenderUpdateOfBidders;
