import React from "react";
import { Link } from "react-router-dom"; // Import for anchor tag if needed
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";
const TableApprovel = ({ data, navigate, fetchData }) => {
  const { hasPermission } = usePermissions();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const getButtonStyle = (status) => {
    switch (status) {
      case "Approve":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[115px] h-[33px] flex-shrink-0 font-bold";
      case "Reject":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[108px] h-[33px] flex-shrink-0 font-bold";
      default:
        return ""; // Default style
    }
  };

  const onApprove = async (memberId) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/approve/member/update/${memberId}`,
        {
          taken_status: "ACTIVE",
        }
      );
      if (response.status === 200) {
        console.log("Member approved successfully");
        alert("Member approved successfully");
        fetchData();
      }
    } catch (error) {
      console.error("Error approving member:", error);
      alert("Error approving member");
    }
  };

  const onReject = async (memberId) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/approve/member/update/${memberId}`,
        {
          taken_status: "REJECTED",
        }
      );
      if (response.status === 200) {
        console.log("Member rejected successfully");
        alert("Member rejected successfully");
      }
    } catch (error) {
      console.error("Error rejecting member:", error);
      alert("Error rejecting member");
    }
  };

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 ">
      <table className="w-full border-collapse border-none">
        <thead>
          <tr className="text-left bg-[#FFF] text-[#9CAC8B] font-bold">
            <th className="py-2 ">Tender ID</th>
            <th className="py-2 ">Committee User ID</th>
            <th className="py-2 ">Committee Appointing Date</th>
            <th className="py-2 ">Committee Role</th>
            <th className="py-2 ">Committee Edit Date</th>
            <th className="py-2 ">Committee Edit User</th>
            <th className="py-2 ">
              Status
              <br /> <p className=" text-[10px]">Approved/Rejected</p>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className="py-2">{item.tender_id}</td>
              <td className="py-2">{item.committee_user_id}</td>
              <td className="py-2">{item.committee_appointing_date}</td>
              <td className="py-2">{item.committee_role}</td>
              <td className="py-2">{item.committee_edit_date}</td>
              <td className="py-2">{item.committee_edit_user_id}</td>
              <td className="py-2">
                <div className="flex">
                  {hasPermission(2680) && (
                    <button
                      className={`inline-block ${getButtonStyle(
                        "Approve"
                      )} text-[#5B6D49]`}
                      onClick={() => onApprove(item._id)}
                    >
                      {"Approve"}
                    </button>
                  )}
                  {hasPermission(2681) && (
                    <button
                      className={`inline-block ${getButtonStyle(
                        "Reject"
                      )} text-[#5B6D49] ml-1`}
                      onClick={() => onReject(item._id)}
                    >
                      {"Reject"}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableApprovel;
