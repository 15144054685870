/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tender_Document_View_Dialog from "./viewTenderDocDialog";
import ViewMore from "./viewmore";

const TableViewTender = ({ data }) => {
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [viewItemsData, setViewItemsData] = useState(null);
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleUpdate = (item) => {
    navigate("/supply/updateTender", {
      state: { selectedRecord: item },
    });
  };
  const [showDialog, setShowDialog] = useState(null);

  const openDialog = (index) => {
    setShowDialog(index);
  };

  const closeDialog = () => {
    setShowDialog(null);
  };

  const handleViewItem = (item) => {
    setViewItemsData(item);
    setViewMoreOpen(true);
  };

  const tableData = data;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[95%] max-h-[500px] overflow-y-auto mt-8 mb-8 min-h-[500px]">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Tender ID</th>
            <th className="px-6 py-6 ">Tender Name</th>
            <th className="px-6 py-6 ">Contract ID</th>
            <th className="px-6 py-6 ">Tender Category</th>
            <th className="px-6 py-6 ">Tender Document</th>
            <th className="px-6 py-6 ">Deadline</th>
            <th className="px-6 py-6 ">Created Date</th>
            <th className="px-6 py-6 ">View Items</th>
            <th className="px-6 py-6 ">Actions</th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.tender_id}</td>
              <td className="px-6 py-2">{item.tender_name}</td>
              <td className="px-6 py-2">{item.contract_id}</td>
              <td className="px-6 py-2">{item.category}</td>
              <td className="px-6 py-2">
                {item.tender_file ? (
                  <a
                    href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/file?filePath=${item.tender_file}`}
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // For security reasons
                    className="px-3 py-1 rounded-full bg-[#5B6D49] rounded-xl text-white hover:underline"
                  >
                    View
                  </a>
                ) : (
                  <span className="px-3 py-1 rounded-xl bg-red-100 text-red-700">
                    No Document
                  </span>
                )}
              </td>
              <td className="px-6 py-2">{item.deadline_date}</td>
              <td className="px-6 py-2">{item.tender_create_date}</td>
              <td className="px-6 py-2">
                <button
                  className="text-white bg-[#5B6D49] rounded-full rounded-xl min-w-[55px]"
                  onClick={() => handleViewItem(item)}
                >
                  View
                </button>
              </td>

              <td className="px-6 py-2">
                <button
                  className="text-black bg-gray-300 rounded-[22px] min-w-[55px]"
                  onClick={() => handleUpdate(item)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {viewMoreOpen && (
        <ViewMore
          onClose={() => setViewMoreOpen(false)}
          viewItems={viewItemsData.procurement_items}
        />
      )}
    </div>
  );
};

export default TableViewTender;
