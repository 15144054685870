import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import FileUpload from "./upload_files";

const Add_payment_entry_popup = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  console.log(backendUrl);
  const [allAccount, setAllAccount] = useState([]);
  const [allparty, setAllParty] = useState([]);
  const [addPymentEntry, setAddPymentEntry] = useState({
    invoice_id: "",
    po_id: "",
    pos_id: "",
    party_type: "Customer",
    payment_type: "",
    entity_id: "",
    transaction_type: "",
    payment_date: "",
    amount: 0,
    payment_method: "",
    description: "",
    prepared_by: "",
    account: {
      from: { id: "", code: "", name: "" },
      to: { id: "", code: "", name: "" },
    },
    payment_file: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [enable, setIsEnable] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [accountRelatedData, setAccountRelatedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReceiveIds, setAllReceive] = useState([]);
  const [allInvoiceIds, setAllInvoiceID] = useState([]);
  const [allpoIds, setPoID] = useState([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setAddPymentEntry((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value,
      };

      // Logic to set transaction_type based on PO ID and Invoice ID
      if (updatedDetails.po_id && updatedDetails.invoice_id) {
        updatedDetails.transaction_type = "Payment";
      } else if (updatedDetails.po_id && updatedDetails.invoice_id === "") {
        updatedDetails.transaction_type = "Advance";
      } else {
        updatedDetails.transaction_type = ""; // Clear transaction_type if neither condition is met
      }

      return updatedDetails;
    });
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    const user = getCookieValue("employee_fullname");

    setAddPymentEntry((prevDetails) => ({
      ...prevDetails,
      payment_file: uploadedFiles[0],
      prepared_by: user,
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setAddPymentEntry({
      invoice_id: "",
      po_id: "",
      pos_id: "",
      party_type: "Customer",
      payment_type: "",
      entity_id: "",
      transaction_type: "",
      payment_date: "",
      amount: 0,
      payment_method: "",
      description: "",
      prepared_by: "",
      account: {
        from: { id: "", code: "", name: "" },
        to: { id: "", code: "", name: "" },
      },
      payment_file: "",
    });
    setIsResetConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const getTableData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        id: item.account_id,
        code: item.account_code,
        name: item.account_name,
      }));

      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const handleAccountTypeFromChange = (e) => {
    const { value } = e.target;

    // Find selected account type
    const selectedAccount = allAccount.find((type) => type.name === value);

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          from: {
            id: selectedAccount.id,
            code: selectedAccount.code,
            name: selectedAccount.name,
          },
        },
      }));
    }
  };

  const handleAccountTypeToChange = (e) => {
    const { value } = e.target;

    // Find selected account type
    const selectedAccount = allAccount.find((type) => type.name === value);

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          to: {
            id: selectedAccount.id,
            code: selectedAccount.code,
            name: selectedAccount.name,
          },
        },
      }));
    }
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getAllPartyType = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyType`,
        {
          params: {
            party_type: addPymentEntry.party_type,
          },
        }
      );

      setAllParty(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerTypeFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allparty.find(
      (type) => type.customer_id === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        entity_id: selectedAccount.customer_id,
      }));
    } else {
      console.log("Selected account not found");
    }
  };

  const handleInvoiceIDChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allInvoiceIds.find(
      (type) => type.invoice_id === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        invoice_id: selectedAccount._id,
      }));
    } else {
      console.log("Invoice Id account not found");
    }
  }

  const handlePOIDFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allpoIds.find(
      (type) => type.po_id === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        po_id: selectedAccount.po_id,
      }));
    } else {
      console.log("PO Id account not found");
    }
  }

  const handlePOSIDFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allReceiveIds.find(
      (type) => type.invoice_id === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        po_id: selectedAccount.invoice_id,
      }));
    } else {
      console.log("POS Id account not found");
    }
  }



  const handleSupplierTypeFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event

    const selectedAccount = allparty.find(
      (type) => type.supplier_name === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        entity_id: selectedAccount.supplier_id,
      }));
    } else {
      console.log("Selected account not found");
    }
  };

  const handleSaveForm = async () => {
    console.log(addPymentEntry);
    // try {
    //   const response = await axios.post(
    //     `${backendUrl}/paymentEntry/add`,
    //     addPymentEntry,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );

    //   // console.log(response.data.message)
    //   setNotification({
    //     message: response.data.message,
    //     type: "success",
    //   });
    // } catch (error) {
    //   console.error("Error", error);
    //   setNotification({
    //     message: "saving purchase Invoice. Please try again.",
    //     type: "error",
    //   });
    // }

    // setIsSaveConfirmationOpen(false);
  };

  const getPoIds = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidPOs`
      );

      console.log(response.data);

      setPoID(response.data);

      // const formattedData = response.data.data.map((item) => ({
      //   id: item.account_id,
      //   code: item.account_code,
      //   name: item.account_name,
      // }));

      // setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const getInvoiceId = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidInvoices`
      );

      console.log(response.data);
      setAllInvoiceID(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const getReceive = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidReceivables`
      );

      console.log(response.data);
      setAllReceive(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };




  useEffect(() => {
    getTableData();
    getPoIds();
    getInvoiceId();
    getReceive();
  }, []);

  useEffect(() => {
    getAllPartyType();
  }, [addPymentEntry.party_type]);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Payment Entry</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-8 gap-x-16 text-lg">
              {/* Payment Type */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Type
                </label>
                <select
                  name="payment_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="Receive">Receive</option>
                  <option value="Pay">Pay</option>
                  <option value="Internal Transfer">Internal Transfer</option>
                </select>
              </div>

              {/* Conditional rendering based on the selected payment type */}
              {addPymentEntry.payment_type === "Pay" && (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    PO ID
                  </label>
                  <select
                    name="pos_id"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                     onChange={handlePOIDFromChange}
                  >
                    <option value=""></option>
                    {allpoIds.map((type) => (
                      <option key={type.po_id} value={type.po_id}>
                        {type.po_id}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Conditional rendering based on the selected payment type */}
              {addPymentEntry.payment_type === "Pay" && (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Invoice ID
                  </label>
                  <select
                    name="invoice_id"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                     onChange={handleInvoiceIDChange}
                  >
                    <option value=""></option>
                    {allInvoiceIds.map((type) => (
                      <option key={type.invoice_id} value={type.invoice_id}>
                        {type.invoice_id}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Conditional rendering based on the selected payment type */}
              {addPymentEntry.payment_type === "Receive" && (
                <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  POS ID
                </label>
                <select
                  name="po_id"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                   onChange={handlePOSIDFromChange}
                >
                  <option value=""></option>
                  {allReceiveIds.map((type) => (
                    <option key={type.reference_id} value={type.reference_id}>
                      {type.reference_id}
                    </option>
                  ))}
                </select>
              </div>
              )}

              {/* Payment Method */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Method
                </label>
                <select
                  name="payment_method"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="Cash">Cash</option>
                  <option value="Credit">Credit</option>
                </select>
              </div>

              {/* Party Type */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Party Type
                </label>
                <select
                  name="party_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                >
                  <option value="Customer">Customer</option>
                  <option value="Supplier">Supplier</option>
                </select>
              </div>

              {/* Party */}
              {addPymentEntry.party_type === "Customer" ? (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Customer Party
                  </label>
                  <select
                    name="party"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                    onChange={handleCustomerTypeFromChange}
                  >
                    <option value=""></option>
                    {allparty.map((type) => (
                      <option key={type.customer_id} value={type.customer_id}>
                        {type.customer_name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Supplier Party
                  </label>
                  <select
                    name="party"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                    onChange={handleSupplierTypeFromChange}
                  >
                    <option value=""></option>
                    {allparty.map((type) => (
                      <option key={type._id} value={type.supplier_name}>
                        {type.supplier_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* From Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account From
                </label>
                <select
                  name="account_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleAccountTypeFromChange}
                >
                  <option value=""></option>
                  {allAccount.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* To Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account To
                </label>
                <select
                  name="name"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleAccountTypeToChange}
                >
                  <option value=""></option>
                  {allAccount.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Amount */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleInputChange}
                />
              </div>

              {/*  Payment Date */}

              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="start_date"
                  className="block text-gray-700 font-semibold mb-1"
                >
                  Payment Date
                </label>
                <input
                  id="payment_date"
                  name="payment_date"
                  // value={purchaseInvoice.invoice_date}
                  type="date"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleInputChange}
                />
              </div>

              {/* Cost Center */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {uploadedFiles.length === 0 && (
              <>
                {/* Section for Document Upload */}
                <h1 className="text-2xl font-bold mt-10 mb-4">
                  Payment Document
                </h1>
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-bold">
                      Click Here to Upload
                    </span>
                    <IoIosCloudUpload className="text-xl" />
                  </div>
                </button>
              </>
            )}

            {/* Display Uploaded Files */}
            {uploadedFiles.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_payment_entry_popup;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
