import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useLocation, Form } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import { format } from "date-fns";
import axios from "axios";
import { toWords } from "number-to-words";

const Purchase_Voucher_Edit = () => {
  const location = useLocation();
  const { tenderData, VoucherData } = location.state;
  const { REACT_APP_API_ENDPOINT } = process.env;

  // Function to format date
  const formatDate = (dateString) => {
    console.log("first");
    const date = new Date(dateString); // Assuming the date string is in a format understood by Date()
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const parseDateString = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  console.log("Tender Data:", tenderData);
  console.log("Voucher Data:", VoucherData);

  const [imagePreview, setImagePreview] = useState(
    `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/file/${VoucherData.approved_img}` ||
      null
  );
  const [imageFirst, setImageFirst] = useState([]);
  const [imagePreviewsecond, setImagePreviewscond] = useState(
    `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/file/${VoucherData.recommended_img}` ||
      null
  );
  const [imageSecond, setImageSecond] = useState([]);
  const [imagePreviewthird, setImagePreviewthird] = useState(
    `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/file/${VoucherData.prepared_img}` ||
      null
  );
  const [imageThird, setImageThird] = useState([]);
  const [imagePreviewfourth, setImagePreviewfourth] = useState(
    `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/file/${VoucherData.checked_img}` ||
      null
  );
  const [imageFourth, setImageFourth] = useState([]);
  const [imagePreviewfifth, setImagePreviewfifth] = useState(
    `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/file/${VoucherData.certified_img}` ||
      null
  );
  const [imageFifth, setImageFifth] = useState([]);
  const [forms, setForms] = useState([]);
  const [serviceStation, setServiceStation] = useState(
    VoucherData.service_station
  );
  const [ledger_account, setLeddgerAccount] = useState(
    VoucherData.ledger_account
  );
  const [cost_ledger_page, setCostLedgerPage] = useState(
    VoucherData.cost_ledger_page
  );
  const [account_code, setAccountCode] = useState(VoucherData.account_code);
  const [cash_voucher_number, setCashVoucherNumber] = useState(
    VoucherData.cash_voucher_number
  );
  const [expenditure_nature, setExpenditureNature] = useState(
    VoucherData.expenditure_nature
  );
  const [cheque_name, setChequeName] = useState(VoucherData.cheque_name);
  const [payee_name_address, setPayeeNameAddress] = useState(
    VoucherData.payee_name_address
  );
  const [total_letters, setTotalLetters] = useState(VoucherData.total_letters);
  const [total_amount, setTotalAmount] = useState(VoucherData.total_amount);
  const [approved_date, setApprovedDate] = useState(
    parseDateString(VoucherData.approved_date)
  );
  const [checked_date, setCheckedDate] = useState(
    parseDateString(VoucherData.checked_date)
  );
  const [recommended_date, setRecommendedDate] = useState(
    parseDateString(VoucherData.recommended_date)
  );
  const [prepared_date, setPreparedDate] = useState(
    parseDateString(VoucherData.prepared_date)
  );
  const [certified_date, setCertifiedDate] = useState(
    parseDateString(VoucherData.certified_date)
  );

  console.log("APPRVE date:", approved_date);

  // Initialize forms state with tender_voucher_amount from VoucherData
  useEffect(() => {
    // Map over VoucherData.tender_voucher_amount and convert dates
    const formattedForms = VoucherData.tender_voucher_amount.map((item) => ({
      tender_voucher_amount_date: parseDateString(
        item.tender_voucher_amount_date
      ),
      tender_voucher_amount_description: item.tender_voucher_amount_description,
      tender_voucher_amount_amount: item.tender_voucher_amount_amount,
    }));

    // Set the forms state with formatted data
    setForms(formattedForms);
  }, [VoucherData.tender_voucher_amount]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageFirst(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Second Signature drag and drop box.
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageSecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewscond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Thired Signature drag and drop box.
  const handleDragOverthird = (e) => {
    e.preventDefault();
  };

  const handleDropthird = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageThird(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthird(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fourth Signature drag and drop box.
  const handleDragOverfourth = (e) => {
    e.preventDefault();
  };

  const handleDropfourth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageFourth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfourth(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fifth Signature drag and drop box.
  const handleDragOverfifth = (e) => {
    e.preventDefault();
  };

  const handleDropfifth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageFifth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfifth(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const addForm = () => {
    setForms([
      ...forms,
      {
        tender_voucher_amount_date: "",
        tender_voucher_amount_description: "",
        tender_voucher_amount_amount: "",
      },
    ]);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedForms = [...forms];
    updatedForms[index][fieldName] = value;
    setForms(updatedForms);

    console.log("Data", value);
  };

  const removeForm = (index) => {
    const updatedForms = [...forms];
    updatedForms.splice(index, 1);
    setForms(updatedForms);
  };

  useEffect(() => {
    if (forms.length > 0) {
      const total = forms.reduce(
        (acc, sample) => acc + Number(sample.tender_voucher_amount_amount),
        0
      );
      setTotalAmount(total);
      setTotalLetters(convertAmountToWords(total));
    } else {
      setTotalAmount(0);
    }
  }, [forms]);

  const convertAmountToWords = (amount) => {
    const singleword = toWords(amount);
    if (amount > 1) {
      return `${singleword} items`;
    } else {
      return `${singleword} item`;
    }
  };

  // Submitting data.
  const handleSubmit = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("tender_id", tenderData.tender_id);
      // formData.append("date", createDate.toLocaleDateString("en-GB"));
      formData.append("service_station", serviceStation);

      formData.append("voucher_number", tenderData.voucher_number);
      formData.append("ledger_account", ledger_account);
      formData.append("cost_ledger_page", cost_ledger_page);
      formData.append("account_code", account_code);

      formData.append("cash_voucher_number", cash_voucher_number);
      formData.append("expenditure_nature", expenditure_nature);
      formData.append("cheque_name", cheque_name);
      formData.append("payee_name_address", payee_name_address);

      //Approved
      formData.append("approved_img", imageFirst);
      formData.append(
        "approved_date",
        approved_date.toLocaleDateString("en-GB")
      );
      formData.append("approved_user_id", "USER-49970751");

      //Recommended
      formData.append("recommended_img", imageSecond);
      formData.append(
        "recommended_date",
        recommended_date.toLocaleDateString("en-GB")
      );
      formData.append("recommended_user_id", "USER-49970751");

      //Certified
      formData.append("certified_img", imageThird);
      formData.append(
        "certified_date",
        certified_date.toLocaleDateString("en-GB")
      );
      formData.append("certified_user_id", "USER-49970751");

      //Prepared
      formData.append("prepared_img", imageFourth);
      formData.append(
        "prepared_date",
        prepared_date.toLocaleDateString("en-GB")
      );
      formData.append("prepared_user_id", "USER-49970751");

      //Checked
      formData.append("checked_img", imageFifth);
      formData.append("checked_date", checked_date.toLocaleDateString("en-GB"));
      formData.append("checked_user_id", "USER-49970751");

      // Voucher Item
      forms.forEach((sampledata, index) => {
        formData.append(
          `voucher_amount[${index}][date]`,
          sampledata.tender_voucher_amount_date.toLocaleDateString("en-GB")
        );
        formData.append(
          `voucher_amount[${index}][description]`,
          sampledata.tender_voucher_amount_description
        );
        formData.append(
          `voucher_amount[${index}][amount]`,
          sampledata.tender_voucher_amount_amount
        );
      });

      formData.append("total_amount", total_amount);
      formData.append("total_letters", total_letters);

      // Make the POST request using Axios
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/update/${tenderData.tender_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      // resetData();
      console.log("Response:", response.data);
      alert("Voucher Edit successfully added");
      // handleItemReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to Edit Voucher");
    }
  };

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen ">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Voucher
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_id}
            disabled
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2 "
            value={tenderData.tender_name}
            disabled
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Edit Voucher
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">Date:</p>
        </div>
        <div className="mt-2">
          <DatePicker
            customInput={
              <div className="relative">
                <input
                  type="text"
                  className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600 "
                  value={VoucherData.date}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                  <FaCalendarAlt className="text-gray-400" />
                </div>
              </div>
            }
            className="rounded-lg border border-gray-300 w-40 h-8 ml-10"
          />
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Service Station:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={serviceStation}
            onChange={(e) => setServiceStation(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8"
            value={VoucherData.voucher_number}
            disabled
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Ledger Account:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={ledger_account}
            onChange={(e) => setLeddgerAccount(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cost Ledger Pg No:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={cost_ledger_page}
            onChange={(e) => setCostLedgerPage(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Account Code No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={account_code}
            onChange={(e) => setAccountCode(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cash Book Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={cash_voucher_number}
            onChange={(e) => setCashVoucherNumber(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Nature of expenditure:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={expenditure_nature}
            onChange={(e) => setExpenditureNature(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name of the Cheques are to be Written:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={cheque_name}
            onChange={(e) => setChequeName(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name and the Address of the Payee:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={payee_name_address}
            onChange={(e) => setPayeeNameAddress(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Third form part */}

      {forms.map((form, index) => (
        <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
          <div className="flex items-center justify-end mt-2">
            <p className="text-[22px] text-[#8691AB] font-bold ml-6">Date:</p>
          </div>
          <div>
            <DatePicker
              selected={form.tender_voucher_amount_date}
              onChange={(date) =>
                handleInputChange(index, "tender_voucher_amount_date", date)
              }
              customInput={
                <div className="relative">
                  <input
                    type="text"
                    className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600"
                    placeholder="Select Date"
                    value={
                      form.tender_voucher_amount_date
                        ? format(form.tender_voucher_amount_date, "dd/MM/yyyy")
                        : ""
                    }
                    readOnly
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                    <FaCalendarAlt className="text-gray-400" />
                  </div>
                </div>
              }
              className="rounded-lg border border-gray-300 w-40 h-8 ml-10 mt-2"
            />
          </div>
          <div className="flex items-center justify-end mt-2">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
              Description:
            </p>
          </div>
          <div className=" row-span-2">
            {" "}
            <textarea
              className=" rounded-[22px] border border-gray-300  ml-10 h-20 mt-2"
              value={form.tender_voucher_amount_description}
              onChange={(e) =>
                handleInputChange(
                  index,
                  "tender_voucher_amount_description",
                  e.target.value
                )
              }
            ></textarea>
          </div>
          <div className="flex items-center justify-end">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
              Quantity :
            </p>
          </div>
          <div>
            {" "}
            <input
              className=" rounded-[22px] border border-gray-300  ml-10 h-8"
              value={form.tender_voucher_amount_amount}
              onChange={(e) =>
                handleInputChange(
                  index,
                  "tender_voucher_amount_amount",
                  e.target.value
                )
              }
            ></input>
          </div>

          {/* Remove button */}
          {
            <div className="flex items-center justify-end mt-2">
              <button
                onClick={() => removeForm(index)}
                className="text-red-500 font-bold mr-20"
              >
                Remove
              </button>
            </div>
          }
        </div>
      ))}
      {/* Add button */}
      <div className="flex justify-end mt-6 mr-6">
        <button
          onClick={addForm}
          className="bg-primary text-white font-bold py-2 px-4 rounded-[22px]"
        >
          Add
        </button>
      </div>

      {/* Forth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total In Letters:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 bm-2"
            value={total_letters}
            onChange={(e) => setTotalLetters(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total Amount:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 mb-2"
            value={total_amount}
            onChange={(e) => setTotalAmount(e.target.value)}
          ></input>
        </div>
      </div>

      <div className=" flex justify-between w-[95%] ml-6">
        {/* First Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}

          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Approval By
                </p>

                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date: </p>
            </div>
            <DatePicker
              selected={approved_date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setApprovedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%] "
              placeholderText="dd/mm/yyyy"
            />
          </div>
        </div>

        {/* Second Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOversecond}
            onDrop={handleDropsecond}
          >
            {imagePreviewsecond ? (
              <img
                src={imagePreviewsecond}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={recommended_date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setRecommendedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%] "
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Theird Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverthird}
            onDrop={handleDropthird}
          >
            {imagePreviewthird ? (
              <img
                src={imagePreviewthird}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={recommended_date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setRecommendedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%] "
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Fourth Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverfourth}
            onDrop={handleDropfourth}
          >
            {imagePreviewfourth ? (
              <img
                src={imagePreviewfourth}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={checked_date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setCheckedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%] "
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Fifth Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverfifth}
            onDrop={handleDropfifth}
          >
            {imagePreviewfifth ? (
              <img
                src={imagePreviewfifth}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={certified_date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setCertifiedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%] "
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 mr-10 flex justify-end">
        <button
          className="bg-[#5B6D49] text-[20px] font-bold text-white rounded-[33px] w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Purchase_Voucher_Edit;
