/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../components/permissions/permission";

function ConfirmationDialog({
  message,
  onConfirm,
  onCancel,
  productDescription,
  selling_price,
  cost_price,
  store,
  location,
  item_name,
  handleProductDescriptionChange,
  handleSelling_price,
  handleCost_price,
  handleStore,
  handleLocation,
  handleItem,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-md shadow-lg">
        <p className="mb-4">{message}</p>

        <input
          type="number"
          value={selling_price}
          onChange={handleSelling_price}
          placeholder="Enter product selling price"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />
        <input
          type="number"
          value={cost_price}
          onChange={handleCost_price}
          placeholder="Enter product cost price"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />
        <select
          // onChange={(e) => handleStoreSelect(index, e)}
          className={
            "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
          }
          value={store} // Ensure value defaults to an empty string if undefined
          onChange={handleStore}
        >
          <option value="">Select Store</option>
          {store.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          // onChange={(e) => handleStoreSelect(index, e)}
          className={
            "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
          }
          value={item_name} // Ensure value defaults to an empty string if undefined
        >
          <option value="">Select Item</option>
          {item_name.map((items) => (
            <option key={items.item_id} value={items.item_name}>
              {items.item_id}- {items.item_name}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={location}
          onChange={handleLocation}
          placeholder="Enter product Location"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />
        <input
          type="text"
          value={productDescription}
          onChange={handleProductDescriptionChange}
          placeholder="Enter product description reason"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />
        <div className="flex justify-end">
          <button
            onClick={onConfirm}
            className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 px-4 py-2 rounded-md"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

const WarehousePending = () => {
  const { hasPermission } = usePermissions();
  const [storeOptions, setStoreOptions] = useState([]);
  const [item_name, setItemData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [recipes, setRecipes] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState(null); // Track the index of the selected recipe
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [productDescription, setProductDescription] = useState(""); // State for product description
  const [productItemStore, setProductItemStore] = useState("");
  const [productCostPrice, setCostPrice] = useState("");
  const [productSellingPrice, setSellingPrice] = useState("");
  const [prodcutItem, setProductItem] = useState("");
  const [prodcutLocation, setLocation] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [fetchKey, setFetchKey] = useState(0);
  // const [itemData, setItemData] = useState([]);
  const [limit, setLimit] = useState(50); // Default to limit of 10
  const [page, setPage] = useState("");
  useEffect(() => {
    loadAllStores();
  }, []);

  const loadAllStores = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/store`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Data:", data); // For debugging

        // Assuming 'data' contains a 'store' array
        const stores = data.map((storeOptions) => ({
          value: storeOptions.store_id,
          label: storeOptions.store_name,
        }));

        console.log("Store Options:", stores); // Verify the store options
        setStoreOptions(stores);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch stores:", errorData);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  // Define a function to fetch data
  const fetchDefaultData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/item/get-all-item?page=${page}&limit=${limit}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setItemData(data.items);
        setPage(data.totalPages);
        // console.log("item data:", data.items);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch items data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, [fetchKey]);

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/finalProduct/finalProductLevelbystatus?product_approve_status=PENDING`
    )
      .then((response) => response.json())
      .then((data) => {
        const extractedRecipes = data.map((recipe) => ({
          id: recipe._id,
          productName: recipe.product_name,
          productCreateBy: recipe.product_create_by,
          status: recipe.product_approve_status,
          productCost: recipe.product_cost,
          productUnitQuantity: recipe.product_unit_quantity,
          productMeasureUnitSymbol: recipe.product_measure_unit_symbol,
          productFinalQuantity: recipe.product_final_quantity,
          productFinalUnitSymbol: recipe.product_final_unit_symbol,
          productCreateAt: recipe.product_create_at,
        }));
        setRecipes(extractedRecipes);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  }, []);

  const handleRejectionConfirmation = () => {
    const recipeId = recipes[selectedRecipeIndex].id;
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/warehouse/moveTowarehouse`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: recipeId,
        product_approve_by: "EMP-0004",
        product_approve_status: "REJECT",
        product_reject_reason: rejectReason,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Recipe status updated to rejected successfully");
          setShowPopup(false);
          setRejectReason("");
        } else {
          console.error("Failed to update recipe status to rejected");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleApproveConfirmation = () => {
    const recipeId = recipes[selectedRecipeIndex].id;
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/warehouse/moveTowarehouse`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: recipeId,
        product_approve_by: "EMP-0004",
        product_approve_status: "APPROVE",
        product_description: productDescription,
        product_store_id: productItemStore,
        item_id: prodcutItem,
        product_cost: productCostPrice,
        product_selling_price: productSellingPrice,
        location: prodcutLocation,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Recipe status updated to approved successfully");
          setShowApproveConfirmation(false);
        } else {
          console.error("Failed to update recipe status to approved");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getStatusColor = (index) => {
    switch (statusMap[index]) {
      case "Approve":
        return "bg-[#F5E450] bg-opacity-50";
      case "Reject":
        return "bg-[#F55050] bg-opacity-50";
      default:
        return "";
    }
  };

  const totalPages = Math.ceil(recipes.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = recipes.slice(startIndex, endIndex);

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  };

  const handleStatusChange = (e, index) => {
    const selectedStatus = e.target.value;

    setStatusMap({ ...statusMap, [index]: selectedStatus }); // Update status map

    if (selectedStatus === "Reject") {
      setShowPopup(true); // Show rejection popup
      setSelectedRecipeIndex(index); // Set the index of the selected recipe
    } else if (selectedStatus === "Approve") {
      setShowApproveConfirmation(true); // Show "Approve" confirmation popup
      setSelectedRecipeIndex(index); // Set the index of the selected recipe
    }
  };

  const handleProductDescriptionChange = (e) => {
    setProductDescription(e.target.value);
  };
  const handleStore = (e) => {
    setProductItemStore(e.target.value);
  };
  const handleItem = (e) => {
    setProductItem(e.target.value);
  };
  const handleCost_price = (e) => {
    setCostPrice(e.target.value);
  };
  const handleSelling_price = (e) => {
    setSellingPrice(e.target.value);
  };
  const handleLocation = (e) => {
    setLocation(e.target.value);
  };

  const handleApprove = () => {
    handleApproveConfirmation(); // Call the confirmation handler
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Pending Productions At Warehouse
      </p>
      <div className="flex mt-10 overflow-x-auto">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_name{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_create_by{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Status
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_cost{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_unit_quantity{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_measure_unit_symbol{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_final_quantity{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_final_unit_symbol{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_create_at{" "}
              </th>
            </tr>
          </thead>

          <tbody>
            {recipes.map((recipe, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{recipe.productName}</td>
                <td className="text-center">{recipe.productCreateBy}</td>
                <td>
                  {hasPermission(2510) && (
                    <select
                      className={`w-full py-1 px-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 font-bold ${getStatusColor(
                        index
                      )}`}
                      value={statusMap[index] || ""}
                      onChange={(e) => handleStatusChange(e, index)} // Pass index to handleStatusChange
                    >
                      <option value="">Select an option</option>
                      <option value="Approve">Approve</option>
                      <option value="Reject">Reject</option>
                    </select>
                  )}
                </td>
                <td className="text-center">{recipe.productCost}</td>
                <td className="text-center">{recipe.productUnitQuantity}</td>
                <td className="text-center">
                  {recipe.productMeasureUnitSymbol}
                </td>
                <td className="text-center">{recipe.productFinalQuantity}</td>
                <td className="text-center">{recipe.productFinalUnitSymbol}</td>
                <td className="text-center">{recipe.productCreateAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showApproveConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to Approve?"
          onConfirm={handleApproveConfirmation}
          onCancel={() => setShowApproveConfirmation(false)}
          productDescription={productDescription}
          handleProductDescriptionChange={handleProductDescriptionChange}
          store={storeOptions} // Pass storeOptions here
          handleStore={handleStore}
          handleItem={handleItem}
          handleCost_price={handleCost_price}
          handleSelling_price={handleSelling_price}
          handleLocation={handleLocation}
          item_name={item_name}
        />
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Mention the reason for rejection below
            </h2>
            <textarea
              value={rejectReason}
              onChange={handleRejectReasonChange}
              className="w-full border rounded-md p-2"
              rows={4}
              placeholder="Enter reason..."
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md mr-4"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleRejectionConfirmation}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
    </div>
  );
};

export default WarehousePending;
