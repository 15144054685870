import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import axios from "axios";

const CompletedTraining = () => {
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [tableRest, setTableReset] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteRowID, setDeleteRowID] = useState("");
    const [trainingType, setTrainingType] = useState(""); 
    const [institution, setInstitution] = useState(""); 
  
    const togglePopup = () => {
      setIsOpen(!isOpen); // Toggle the isOpen state
    };
  
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    // Define state for start date and end date
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [row, setRow] = useState();
    const [editOpen, setEditOpen] = useState();
    const [notification, setNotification] = useState({ message: "", type: "" });
  
    const rowsPerPage = 5; // Number of rows per page
  
    // Calculate the number of pages
    const totalPages = Math.ceil(tableData.length / rowsPerPage);
  
    // Get the current rows for the page
    const currentRows = tableData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  
    // Helper function to format date to 'YYYY-MM-DD'
    const formatDateForInput = (date) => {
      if (!date) return ""; // Return an empty string if date is invalid
      const formattedDate = new Date(date);
      return isNaN(formattedDate) ? "" : formattedDate.toISOString().slice(0, 10); // Format to 'YYYY-MM-DD'
    };
  
    // Handle Notification Close
    const handleCloseNotification = () => {
      setNotification({ message: "", type: "" });
    };
    // Function to handle page change
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    // Function to toggle dropdown
    const toggleDropdown = () => {
      setDropdownOpen((prev) => !prev);
    };
  
    // Function to handle CSV export
    const handleExportCSV = () => {
      // const csvData = tableData.map((obj) => ({
      //   SampleID: obj.sample_id,
      //   SampleType: obj.sample_type,
      //   SampleName: obj.sample_name,
      //   Amount: obj.amount,
      //   TenderBulkReceivedDate: obj.received_date,
      // }));
      // const csv = Papa.unparse(csvData);
      // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      // saveAs(blob, "Sample_Allocation_data.csv");
    };

  
    // Function to handle search term change
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const editSampleAllocation = (row) => {
      setRow(row);
      setEditOpen(true);
    };
  
 
  
    const getTableData = async () => {
      try {
        // Construct the parameters object
        const params = {};
  
        if (searchTerm) {
          params.sample_id = searchTerm;
        }
        if (startDate) {
          params.start_date = startDate;
        }
        if (endDate) {
          params.end_date = endDate;
        }
  
        // Make the GET request with query parameters
        const response = await axios.get(
          `${backendUrl}/v1/hris/performance/training/gettraining`, // The endpoint
       
        );

         const data = response.data
        //  console.log(data)
        //  setTableData(data);
      } catch (error) {
        console.error("Error fetching sample details:", error);
      }
    };
  
    const deleteSampleAllocation = (rowId) => {
      setDeleteRowID(rowId);
      setDeleteConfirmationOpen(true);
    };
  
   
    const handleTrainingTypeChange = (event) => {
      setTrainingType(event.target.value); // Handle change for Training Type dropdown
    };
  
    const handleInstitutionChange = (event) => {
      setInstitution(event.target.value); // Handle change for Institution dropdown
    };
  

  
    useEffect(() => {
      getTableData();
    }, [searchTerm, startDate, endDate]);
  
    return (
      <div className="mx-10 mt-5">
        <div className="overflow-hidden">
          <div className="flex items-center justify-between mt-6">
            <div>
              <p className="text-[30px] font-semibold">Completed Training</p>
            </div>
          </div>
  
      
  
          {/* Controls with Add Button */}
          <div className="mt-5">
            <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
              <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                {/* Search Box with Manual Width */}
                <div className="w-full mt-6 md:w-[300px]">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
  
                 {/* Training Type Dropdown */}
                 <div className="w-full md:w-[200px]">
                  <label className="block mb-1 text-gray-700">Training Type</label>
                  <select
                    value={trainingType}
                    onChange={handleTrainingTypeChange}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Training Type</option>
                    <option value="type1">Type 1</option>
                    <option value="type2">Type 2</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
  
                {/* Institution Dropdown */}
                <div className="w-full md:w-[200px]">
                  <label className="block mb-1 text-gray-700">Institution</label>
                  <select
                    value={institution}
                    onChange={handleInstitutionChange}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Institution</option>
                    <option value="institution1">Institution 1</option>
                    <option value="institution2">Institution 2</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>
  
              {/* Right-Side Buttons */}
              <div className="relative flex items-center gap-4 mt-4 md:mt-0">
  
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                  onClick={handleExportCSV}
                >
                  <IoAdd className="text-xl" />
                  CSV to Export
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div className="my-4 overflow-x-auto mt-14">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Training Name
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Training Type
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Institution
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Durating
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Cost of Training
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                  Pariticipated Employeee
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                   Feedback
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentRows.map((row) => (
                <tr key={row.training_id}>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.sample_id}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.training_type}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.institute}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.duration}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.cost_of_the_training}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {row.participated_employees}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {'null'}
                  </td>
          
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`px-3 py-1 mx-1 text-sm rounded ${
                currentPage === i + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
  
        {/* Add Sample Allocation Process Popup */}
        {/* {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <AddSampleAllocation
                togglePopup={togglePopup}
                resetTable={setTableReset}
              />
            </div>
          </div>
        )} */}
  
        {/* Edit Sample Allocation Process Popup */}
        {/* {editOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <EditSampleAllocation togglePopup={closeEditModal} row={row} />
            </div>
          </div>
        )} */}
  

      </div>
    );
};

export default CompletedTraining;
