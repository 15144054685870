import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import IdGenerate from "../../../../utils/id_generate";
import { toWords } from "number-to-words";
import axios from "axios";

const Purchase_Voucher_Create = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewscond] = useState(null);
  const [imageSecond, setImageSecond] = useState(null);
  const [imagePreviewthird, setImagePreviewthird] = useState(null);
  const [imageThird, setImageThird] = useState(null);
  const [imagePreviewfourth, setImagePreviewfourth] = useState(null);
  const [imageFourth, setImageFourth] = useState(null);
  const [imagePreviewfifth, setImagePreviewfifth] = useState(null);
  const [imageFifth, setImageFifth] = useState(null);
  const [forms, setForms] = useState([]);
  const location = useLocation();
  const tenderData = location.state;
  const [createDate, setCreateDate] = useState(new Date());
  const [serviceStation, setServiceStation] = useState("");
  const [voucherNo, setVoucherNo] = useState(IdGenerate("PUR"));
  const [ledgerAccount, setLedgerAccount] = useState("");
  const [costLedgerPgNo, setCostLedgerPgNo] = useState("");
  const [accountCodeNo, setAccountCodeNo] = useState("");
  const [cashBookVoucherNo, setCashBookVoucherNo] = useState("");
  const [natureOfExpendinture, setNatureOfExpendinture] = useState("");
  const [nameOfChequesToBe, setNameOfChequesToBe] = useState("");
  const [nameAndAddressPayee, setNameAndAddressPayee] = useState("");
  const [totalInLetter, setTotalInLetter] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [sertifiedDate, setSertifiedDate] = useState(new Date());
  const [checkedDate, setCheckedDate] = useState(new Date());
  const [preparedDate, setPreparedDate] = useState(new Date());
  const [recommnededDate, setRecommendedDate] = useState(new Date());
  const [ApprovedDate, setApproveDate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;
  useEffect(() => {
    // Initialize forms state with one empty form object
    setForms([{ date: "", description: "", quantity: "" }]);
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Second Signature drag and drop box.
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageSecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewscond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Thired Signature drag and drop box.
  const handleDragOverthird = (e) => {
    e.preventDefault();
  };

  const handleDropthird = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageThird(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthird(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fourth Signature drag and drop box.
  const handleDragOverfourth = (e) => {
    e.preventDefault();
  };

  const handleDropfourth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageFourth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfourth(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fifth Signature drag and drop box.
  const handleDragOverfifth = (e) => {
    e.preventDefault();
  };

  const handleDropfifth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImageFifth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfifth(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const addForm = () => {
    setForms([...forms, { date: "", description: "", quantity: "" }]);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedForms = [...forms];
    updatedForms[index][fieldName] = value;
    setForms(updatedForms);
  };

  const removeForm = (index) => {
    const updatedForms = [...forms];
    updatedForms.splice(index, 1);
    setForms(updatedForms);
  };

  useEffect(() => {
    if (forms.length > 0) {
      const total = forms.reduce(
        (acc, sample) => acc + Number(sample.quantity),
        0
      );
      setTotalAmount(total);
      setTotalInLetter(convertAmountToWords(total));
    } else {
      setTotalAmount(0);
    }
  }, [forms]);

  const convertAmountToWords = (amount) => {
    const singleword = toWords(amount);
    if (amount > 1) {
      return `${singleword} items`;
    } else {
      return `${singleword} item`;
    }
  };

  // Submitting data.
  const handleSubmit = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("tender_id", tenderData.tender_id);
      formData.append("date", createDate.toLocaleDateString("en-GB"));
      formData.append("service_station", serviceStation);

      formData.append("voucher_number", voucherNo);
      formData.append("ledger_account", ledgerAccount);
      formData.append("cost_ledger_page", costLedgerPgNo);
      formData.append("account_code", accountCodeNo);

      formData.append("cash_voucher_number", cashBookVoucherNo);
      formData.append("expenditure_nature", natureOfExpendinture);
      formData.append("cheque_name", nameOfChequesToBe);
      formData.append("payee_name_address", nameAndAddressPayee);

      //Approved
      formData.append("approved_img", image);
      formData.append(
        "approved_date",
        ApprovedDate.toLocaleDateString("en-GB")
      );
      formData.append("approved_user_id", "USER-49970751");

      //Recommended
      formData.append("recommended_img", imageSecond);
      formData.append(
        "recommended_date",
        recommnededDate.toLocaleDateString("en-GB")
      );
      formData.append("recommended_user_id", "USER-49970751");

      //Certified
      formData.append("certified_img", imageThird);
      formData.append(
        "certified_date",
        sertifiedDate.toLocaleDateString("en-GB")
      );
      formData.append("certified_user_id", "USER-49970751");

      //Prepared
      formData.append("prepared_img", imageFourth);
      formData.append(
        "prepared_date",
        preparedDate.toLocaleDateString("en-GB")
      );
      formData.append("prepared_user_id", "USER-49970751");

      //Checked
      formData.append("checked_img", imageFifth);
      formData.append("checked_date", ApprovedDate.toLocaleDateString("en-GB"));
      formData.append("checked_user_id", "USER-49970751");

      // Voucher Item
      forms.forEach((sampledata, index) => {
        formData.append(
          `voucher_amount[${index}][date]`,
          sampledata.date.toLocaleDateString("en-GB")
        );
        formData.append(
          `voucher_amount[${index}][description]`,
          sampledata.description
        );
        formData.append(
          `voucher_amount[${index}][amount]`,
          sampledata.quantity
        );
      });

      formData.append("total_amount", totalAmount);
      formData.append("total_letters", totalInLetter);

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      resetData();
      console.log("Response:", response.data);
      alert("Voucher Add successfully added");
      // handleItemReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Voucher");
    }
  };

  const resetData = () => {
    setImagePreview(null);
    setImage(null);
    setImagePreviewscond(null);
    setImageSecond(null);
    setImagePreviewthird(null);
    setImageThird(null);
    setImagePreviewfourth(null);
    setImageFourth(null);
    setImagePreviewfifth(null);
    setImageFifth(null);
    setForms([]);
    setCreateDate(new Date());
    setServiceStation("");
    setVoucherNo(IdGenerate("PUR"));
    setLedgerAccount("");
    setCostLedgerPgNo("");
    setAccountCodeNo("");
    setCashBookVoucherNo("");
    setNatureOfExpendinture("");
    setNameOfChequesToBe("");
    setNameAndAddressPayee("");
    setTotalInLetter("");
    setTotalAmount("");
    setSertifiedDate(new Date());
    setCheckedDate(new Date());
    setPreparedDate(new Date());
    setRecommendedDate(new Date());
    setApproveDate(new Date());
  };

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen ">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Voucher
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_id}
            disabled
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2 "
            value={tenderData.tender_name}
            disabled
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Create a Voucher
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center  mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6 ">Date:</p>
        </div>
        <div className="mt-2">
          <DatePicker
            customInput={
              <div className="relative">
                <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                  <FaCalendarAlt className="text-gray-400" />
                </div>
                <input
                  value={createDate.toLocaleDateString("en-GB")}
                  disabled
                  type="text"
                  className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600 "
                  placeholder="Select Date"
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            }
            className="rounded-lg border border-gray-300 w-40 h-8 ml-10"
          />
        </div>
        <div className="flex items-center  mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Service Station:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%] mt-2"
            value={serviceStation}
            onChange={(e) => setServiceStation(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
            value={voucherNo}
            disabled
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Ledger Account:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 w-[80%]"
            value={ledgerAccount}
            onChange={(e) => setLedgerAccount(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cost Ledger Pg No:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 w-[80%]"
            value={costLedgerPgNo}
            onChange={(e) => setCostLedgerPgNo(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Account Code No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
            value={accountCodeNo}
            onChange={(e) => setAccountCodeNo(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cash Book Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
            value={cashBookVoucherNo}
            onChange={(e) => setCashBookVoucherNo(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Nature of expenditure:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
            value={natureOfExpendinture}
            onChange={(e) => setNatureOfExpendinture(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name of the Cheques are to be Written:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
            value={nameOfChequesToBe}
            onChange={(e) => setNameOfChequesToBe(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center  ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name and the Address of the Payee:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%] "
            value={nameAndAddressPayee}
            onChange={(e) => setNameAndAddressPayee(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Third form part */}

      {forms.map((form, index) => (
        <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
          <div className="flex items-center  mt-2">
            <p className="text-[22px] text-[#8691AB] font-bold ml-6">Date:</p>
          </div>
          <div>
            <DatePicker
              customInput={
                <div className="relative">
                  <input
                    type="text"
                    className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600"
                    placeholder="Select Date"
                    f
                    // Set the value to the date from your form state
                    value={
                      form.date ? form.date.toLocaleDateString("en-GB") : ""
                    } // Handle potential undefined date
                    onChange={(e) =>
                      handleInputChange(index, "date", e.target.value)
                    }
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                    <FaCalendarAlt className="text-gray-400" />
                  </div>
                </div>
              }
              className="rounded-lg border border-gray-300 w-40 h-8 ml-10 mt-2"
              selected={form.date} // Assuming form.date is a Date object
              onChange={(date) => handleInputChange(index, "date", date)} // Update form state on change
            />
          </div>
          <div className="flex items-center  mt-2">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
              Description:
            </p>
          </div>
          <div className=" row-span-2">
            {" "}
            <textarea
              className=" rounded-[22px] border border-gray-300  ml-10 h-20 mt-2 w-[80%]"
              value={form.description}
              onChange={(e) =>
                handleInputChange(index, "description", e.target.value)
              }
            ></textarea>
          </div>
          <div className="flex items-center ">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
              Quantity :
            </p>
          </div>
          <div>
            <input
              className=" rounded-[22px] border border-gray-300  ml-10 h-8 w-[80%]"
              type="number"
              value={form.quantity}
              onChange={(e) =>
                handleInputChange(index, "quantity", e.target.value)
              }
            ></input>
          </div>

          {/* Remove button */}
          {index !== 0 && (
            <div className="flex items-center justify-end mt-2">
              <button
                onClick={() => removeForm(index)}
                className="text-red-500 font-bold mr-20"
              >
                Remove
              </button>
            </div>
          )}
        </div>
      ))}
      {/* Add button */}
      <div className="flex justify-end mt-6 mr-6">
        <button
          onClick={addForm}
          className="bg-primary text-white font-bold py-2 px-4 rounded-[22px]"
        >
          Add
        </button>
      </div>

      {/* Forth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total In Letters:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 bm-2 w-[80%]"
            value={totalInLetter}
            disabled
          ></input>
        </div>
        <div className="flex items-center  mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total Amount:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 mb-2 w-[80%]"
            value={totalAmount}
            disabled
          ></input>
        </div>
      </div>

      <div className=" flex justify-between w-[95%] ml-6">
        {/* First Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Approved By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={ApprovedDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setApproveDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[100%]"
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Second Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOversecond}
            onDrop={handleDropsecond}
          >
            {imagePreviewsecond ? (
              <img
                src={imagePreviewsecond}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Recommended By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={recommnededDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setRecommendedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%]"
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Theird Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverthird}
            onDrop={handleDropthird}
          >
            {imagePreviewthird ? (
              <img
                src={imagePreviewthird}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={preparedDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setPreparedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%]"
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Fourth Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverfourth}
            onDrop={handleDropfourth}
          >
            {imagePreviewfourth ? (
              <img
                src={imagePreviewfourth}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Checked By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={checkedDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setCheckedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%]"
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>

        {/* Fifth Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverfifth}
            onDrop={handleDropfifth}
          >
            {imagePreviewfifth ? (
              <img
                src={imagePreviewfifth}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Certified By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <DatePicker
              selected={sertifiedDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setSertifiedDate(date)}
              className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF] w-[90%]"
              placeholderText="dd-mm-yyyy"
            />
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 mr-10 flex justify-end">
        <button
          className="bg-[#5B6D49] text-[20px] font-bold text-white rounded-[33px] w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Purchase_Voucher_Create;
