/** @format */

import React, { useState, useEffect, useCallback } from "react";

const Production_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = {
    3: { children: [120, 121, 122, 123, 124, 125] },
    120: { children: [1300, 1310, 1320, 1330] },
    121: { children: [1340, 1350, 1360, 1370] },
    122: { children: [1400] },
    123: { children: [1410] },
    124: { children: [1420] },
    125: { children: [1430, 1440, 1450, 1460, 1470] },
    1300: { children: [2400, 2401] },
    1310: { children: [2410, 2411] },
    1320: { children: [2420] },
    1330: { children: [2430] },
    1350: { children: [2450] },
    1430: { children: [2500, 2501, 2502, 2503] },
    1440: { children: [2510] },
    1470: { children: [2520, 2521, 2522] },

    // Add the rest of your permissions here
  };

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure production permission ID (37) is included if any production permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[3].children.includes(perm)
      ) &&
      !selectedPermissions.includes(3)
    ) {
      selectedPermissions.push(3);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Production
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Recipe
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1300"
                      checked={!!checkedValues[1300]}
                      onChange={() => handleCheckboxChange(1300)}
                    />
                    Create Recipe
                  </label>
                  <div className="ml-[9%]">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2400"
                          checked={!!checkedValues[2400]}
                          onChange={() => handleCheckboxChange(2400, 1300)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2401"
                          checked={!!checkedValues[2401]}
                          onChange={() => handleCheckboxChange(2401, 1300)}
                        />
                        Submit
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1310"
                      checked={!!checkedValues[1310]}
                      onChange={() => handleCheckboxChange(1310)}
                    />
                    Pending Recipes
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2410"
                          checked={!!checkedValues[2410]}
                          onChange={() => handleCheckboxChange(2410, 1310)}
                        />
                        View
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2411"
                          checked={!!checkedValues[2411]}
                          onChange={() => handleCheckboxChange(2411, 1310)}
                        />
                        Action
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1320"
                      checked={!!checkedValues[1320]}
                      onChange={() => handleCheckboxChange(1320)}
                    />
                    Approved Recipes
                  </label>
                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2420"
                        checked={!!checkedValues[2420]}
                        onChange={() => handleCheckboxChange(2420, 1320)}
                      />
                      View
                    </label>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1330"
                      checked={!!checkedValues[1330]}
                      onChange={() => handleCheckboxChange(1330)}
                    />
                    Rejected Recipes
                  </label>
                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2430"
                        checked={!!checkedValues[2430]}
                        onChange={() => handleCheckboxChange(2430, 1330)}
                      />
                      View
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  JOB
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1340"
                      checked={!!checkedValues[1340]}
                      onChange={() => handleCheckboxChange(1340)}
                    />
                    Create Job
                  </label>
                  <div className="ml-[9%]"></div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1350"
                      checked={!!checkedValues[1350]}
                      onChange={() => handleCheckboxChange(1350)}
                    />
                    Pending Job
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2450"
                          checked={!!checkedValues[2450]}
                          onChange={() => handleCheckboxChange(2450, 1350)}
                        />
                        Approve
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1360"
                      checked={!!checkedValues[1360]}
                      onChange={() => handleCheckboxChange(1360)}
                    />
                    Approved Job
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1370"
                      checked={!!checkedValues[1370]}
                      onChange={() => handleCheckboxChange(1370)}
                    />
                    Rejected Job
                  </label>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Unit
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1400"
                      checked={!!checkedValues[1400]}
                      onChange={() => handleCheckboxChange(1400)}
                    />
                    Make Units
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Manage Production
                </p>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="1410"
                        checked={!!checkedValues[1410]}
                        onChange={() => handleCheckboxChange(1410)}
                      />
                      Add Stage
                    </label>
                  </div>
                </div>
                <div className="ml-5 mt-2"></div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Production
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1420"
                      checked={!!checkedValues[1420]}
                      onChange={() => handleCheckboxChange(1420)}
                    />
                    Start Production
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Warehouse
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1430"
                      checked={!!checkedValues[1430]}
                      onChange={() => handleCheckboxChange(1430)}
                    />
                    Warehouse Management
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2500"
                          checked={!!checkedValues[2500]}
                          onChange={() => handleCheckboxChange(2500, 1430)}
                        />
                        Submit
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2501"
                          checked={!!checkedValues[2501]}
                          onChange={() => handleCheckboxChange(2501, 1430)}
                        />
                        Submit Adjustment
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2502"
                          checked={!!checkedValues[2502]}
                          onChange={() => handleCheckboxChange(2502, 1430)}
                        />
                        End Production
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2503"
                          checked={!!checkedValues[2503]}
                          onChange={() => handleCheckboxChange(2503, 1430)}
                        />
                        Add New Stage
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1440"
                      checked={!!checkedValues[1440]}
                      onChange={() => handleCheckboxChange(1440)}
                    />
                    Warehouse Pending
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2510"
                          checked={!!checkedValues[2510]}
                          onChange={() => handleCheckboxChange(2510, 1440)}
                        />
                        Approve
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1450"
                      checked={!!checkedValues[1450]}
                      onChange={() => handleCheckboxChange(1450)}
                    />
                    Warehouse Approved
                  </label>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1460"
                      checked={!!checkedValues[1460]}
                      onChange={() => handleCheckboxChange(1460)}
                    />
                    Warehouse Rejected
                  </label>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1470"
                      checked={!!checkedValues[1470]}
                      onChange={() => handleCheckboxChange(1470)}
                    />
                    Warehouse Adjustment
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2520"
                          checked={!!checkedValues[2520]}
                          onChange={() => handleCheckboxChange(2520, 1470)}
                        />
                        Approve 1
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2521"
                          checked={!!checkedValues[2521]}
                          onChange={() => handleCheckboxChange(2521, 1470)}
                        />
                        Approve 2
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2522"
                          checked={!!checkedValues[2522]}
                          onChange={() => handleCheckboxChange(2522, 1470)}
                        />
                        Approve 3
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Production_Permission;
