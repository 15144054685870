/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import TableInsideCard from "./ViewsendQuatation";

const DialogBoxChairman = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[80%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-addBtn font-poppins font-semibold text-[26px] text-center mt-4">
            Upload Quotations
          </h2>
        </div>
        <div className="flex justify-center items-center mt-4 h-[80%]">
          <div className="bg-[#FFF] rounded-[19px] w-[90%] h-[80%] flex-shrink-0">
            <TableInsideCard navigate={navigate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBoxChairman;
