/** @format */

import React, { useEffect, useState } from "react";
import "./inventoryDashboard.css";
import axios from "axios";
import ViewMore from "./viewmore";
import Cookies from "js-cookie";

import Cube from "../../../Assets/icons/Cube-2.png";
import Sphere from "../../../Assets/icons/Sphere-Green-Glossy.png";
import Toroid from "../../../Assets/icons/SuperToroid-2.png";

import Card1 from "../../../components/card 1/card1";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryDashboard() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  //get all available items
  const [AvailableItems, setAvailableItems] = useState([]);
  const [TotalAvailableItems, setTotalAvailableItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [page, setPage] = useState("");
  const [pageSize, setPageSize] = useState(10000);
  const { REACT_APP_API_ENDPOINT } = process.env;

  // const GetAllAvailableItems = async () => {
  //   const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/Dashboard/stock-data?page=${page}&pageSize=${pageSize}`;
  //   try {
  //     const response = await fetch(endpoint);
  //     if (response.ok) {
  //       const data = await response.json();
  //       setTotalAvailableItems(data.items);
  //       // console.log("item data:", data.items);
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Failed to fetch items data:", errorData);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while fetching items data:", error);
  //   }
  // };

  const GetAllAvailableItems = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dashboard/stock-data?page=${page}&pageSize=${pageSize}`
      );
      if (!res.ok) {
        // Log the response text for debugging purposes
        const errorText = await res.text();
        console.error("Error occurred while fetching items data:", errorText);
        throw new Error("Failed to fetch items data");
      }
      const data = await res.json();
      setTotalAvailableItems(data.items);
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  // useEffect(() => {
  //   GetAllAvailableItems();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(
  //       `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
  //     );
  //     const data = await response.json();
  //     setItemCategoryData(data);
  //     // console.log("Items:", itemCategoryData);
  //   } catch (error) {
  //     console.error("Error fetching Item Category data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleViewMore = (inventory) => {
    setViewMoreData(inventory);
    setViewMoreOpen(true);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Dashboard</p>

      {/* <div className="flex justify-between items-center w-full">
        {hasPermission(1280) && (
          <Card1
            title1="Total "
            title2="Available"
            subTitle="Item Qty."
            value={TotalAvailableItems.length}
            img={Cube}
          />
        )}{" "}
        {hasPermission(1281) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Sphere}
          />
        )}
        {hasPermission(1282) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Toroid}
          />
        )}
      </div> */}

      <div className="flex justify-between items-center mt-[50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Item Name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            {/* <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={categoryID}
              onChange={(e) => setCategoryID(e.target.value)}
            >
              <option value="">Item Category Type</option>
              {itemCategoryData.map((items, index) => (
                <option key={index} value={items.sub_category_id}>
                  {items.item_category_name} - {items.item_sub_category_name}
                </option>
              ))}
            </select> */}

            <button
              className="w-[200px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 p2 text-white"
              onClick={""}
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Item ID</th>
              <th className="text-primary px-4 py-2">Item Name</th>
              <th className="text-primary px-4 py-2">Item Measure Unit</th>
              <th className="text-primary px-4 py-2">Item Category ID</th>
              <th className="text-primary px-4 py-2">Available Qty</th>
              <th className="text-primary px-4 py-2">View Items</th>
            </tr>
          </thead>
          <tbody>
            {/* Error state */}

            {/* No data state */}
            {TotalAvailableItems.length === 0 && (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
            {/* Render data */}
            {TotalAvailableItems.map((inventory) => (
              <tr key={inventory.item_id} className="">
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_name}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_measure_unit}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.item_category_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {inventory.total_shadow_qty}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  <button
                    className="text-primary hover:underline"
                    onClick={() => handleViewMore(inventory)}
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {viewMoreOpen && (
        <ViewMore
          onClose={() => setViewMoreOpen(false)} // Pass the dispose_items as a prop
          disposeItems={viewMoreData.dispose_items}
        />
      )}
    </div>
  );
}
