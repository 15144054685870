import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import Add_sales_invoice_popup from "./add_sales_invice";
import usePermissions from "../../../components/permissions/permission";
// import Edit_purchase_popup from "./edit_purchase_invoice_popup";

const Sales_invoice = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [seletedValue, setSeletedValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const { hasPermission } = usePermissions();

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    console.log("Delete row: ", selectedRow);
    try {
      const response = await axios.put(
        `${backendUrl}/account/getFirstAndSecondLevelAccTypes`
      );
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/receivable/salesInvoice/getSalesInvoice`,
        { params }
      );
      console.log("-----sales-invoice-------");
      console.log(response.data);
      console.log("-----sales-invoice-------");

      // Ensure you set both data and pagination state
      if (response.data) {
        console.log(response.data);
         setTableData(response.data.data); // Set the data array
         setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  // Function to handle PDF download
  const downloadPDF = async (pdfUrl, fileName) => {
    try {
      // Fetch the PDF from the backend (change the URL as needed)
      const response = await axios.get(
        `${backendUrl}/paymentEntry/downloadPaymentDocument/85`,
        {
          responseType: "blob", // Ensure the response is in Blob format (binary)
        }
      );

      // Create a Blob from the PDF response
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = fileName; // Set the file name for the download
      document.body.appendChild(link); // Append the link to the document
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link after the download
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
    // console.log(seletedValue)
  }, [rowsPerPage, pagination.current_page, seletedValue]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Sales Invoice
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/*  Customer Name  */}
              <div className="w-full md:w-[250px] relative">
                <label
                  htmlFor="customer_name"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Customer Name
                </label>
                <input
                  id="customer_name"
                  name="customer_name"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                />
              </div>

              {/*  Prepared By	 */}
              <div className="w-full md:w-[250px] relative">
                <label
                  htmlFor="prepared_by"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Prepared By
                </label>
                <input
                  id="prepared_by"
                  name="prepared_by"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                />
              </div>

              {/*  Invoice ID	 */}
              <div className="w-full md:w-[250px] relative">
                <label
                  htmlFor="invoice_id"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Invoice ID
                </label>
                <input
                  id="invoice_id"
                  name="invoice_id"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                />
              </div>

              {/* Status */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="payment_type"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Status
                </label>
                <select
                  id="payment_type"
                  name="payment_type"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Receive">Receive</option>
                  <option value="Pay">Pay</option>
                  <option value="Internal Transfer">Internal Transfer</option>
                </select>
              </div>
            </div>

            {/* Right-Side Button or Dropdown */}
            {/* Right-Side Button or Dropdown */}
            <div className="relative flex items-center gap-4 md:mt-0">
              {selectedRow ? (
                // Action Dropdown
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                      <button
                        onClick={handleEdit}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FaEdit className="mr-2" /> Edit
                      </button>
                      <button
                        onClick={handleDelete}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FaTrash className="mr-2" /> Delete
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                hasPermission(1980) && (
                  // Add Button
                  <button
                    className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={togglePopup}
                  >
                    <IoAdd className="text-xl" />
                    Add Button
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-20">
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black mt-12">
            <thead className="bg-gray-200">
              {/* Background color to distinguish header */}
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Customer Name
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Status
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Total
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Document
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRow === row.payment_id ? "bg-blue-200" : ""
                  } hover:bg-gray-100`}
                  onClick={() => handleRowSelect(row)}
                >
                  <td className="px-6 py-4 border-b border-black">{"null"}</td>
                  <td className="px-6 py-4 border-b border-black">{"null"}</td>

                  <td className="px-6 py-4 border-b border-black">
                    {/* {new Date(row.prepared_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long", // Use "short" for abbreviated month names
                      day: "2-digit",
                    })} */}

                    {"null"}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.po_id}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    <button
                      className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                      onClick={() =>
                        downloadPDF(row.document_url, `${row.document_url}.pdf`)
                      }
                    >
                      Download PDF
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
          <button
            key={size}
            onClick={() => handleRowsPerPageChange(size)}
            className={`px-4 py-2 ${
              rowsPerPage === size ? "bg-gray-800 text-white" : "bg-gray-300"
            } rounded`}
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              current_page: Math.min(
                prevPagination.current_page + 1,
                prevPagination.total_pages
              ),
            }))
          }
          disabled={pagination.current_page >= pagination.total_pages}
          className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
        >
          Load More
        </button>
      </div>

      {/* Add sales invoice entry Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Add_sales_invoice_popup togglePopup={togglePopup} />
          </div>
        </div>
      )}

      {/* edit purchase invoice entry Popup */}
      {/* {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Edit_purchase_popup editePopup={editePopup} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Sales_invoice;
