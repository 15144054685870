import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { keyframes } from "styled-components";
import TecBidEvaluationReport from "./tec_bid_evaluation_report";

const Bid_Evaluation_Summary_Report_view = () => {
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const location = useLocation();
  const tenderId = location.state;

  const pdfView = () => {
    navigate("/voucher/view/pdf", {
      state: data,
    });
  };

  const [nameOfProcurementEntity, setNameOfProcurementEntity] = useState("");
  const [nameOfProcurement, setNameOfProcurement] = useState("");
  const [sourceFinacialResources, setSourceFinacialResources] = useState("");
  const [prebidEstimatedAmount, setPrebidEstimatedAmount] = useState("");
  const [methodOfProcurement, setMethodOfProcurement] = useState("");
  const [numbersOfbidersDocument, setNumbersOfbidersDocument] = useState("");
  const [numberOfBidsReceived, setNumberOfBidsReceived] = useState("");
  const [numberOfbidsDeterminded, setNumberOfbidsDeterminded] = useState("");
  const [anyCommonReasons, setAnyCommonReasons] = useState("");
  const [bidderRecommendation, setBidderRecommendation] = useState("");
  const [ifTheAnswertoAbove, setIfTheAnswertoAbove] = useState("");
  const [recommendedPrice, setRecommendedPrice] = useState("");
  const [recommendedBidderPrice, setRecommendedBidderPrice] = useState("");
  const [deferentBetweenReason, setDeferentBetweenReason] = useState("");
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/bid_evaluation_form/get/${tenderId.tender_id}`
        );
        const data = response.data[0];
        setData(data);
        setNameOfProcurementEntity(data.proc_entity || "");
        setNameOfProcurement(data.proc_name || "");
        setSourceFinacialResources(data.fin_source || "");
        setPrebidEstimatedAmount(data.pre_bid_est_amt || "");
        setMethodOfProcurement(data.proc_method || "");
        setNumbersOfbidersDocument(data.docs_issued || "");
        setNumberOfBidsReceived(data.bids_received || "");
        setNumberOfbidsDeterminded(data.responsive_bids || "");
        setAnyCommonReasons(data.non_resp_reasons || "");
        setBidderRecommendation(data.low_bid_award || "");
        setIfTheAnswertoAbove(data.no_award_reason || "");
        setRecommendedPrice(data.rec_offer_price || "");
        setRecommendedBidderPrice(data.bid_price_open || "");
        setDeferentBetweenReason(data.price_diff || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tenderId]);

  const componentRef = useRef();

  return (
    <div className="bg-background overflow-y-auto h-screen" ref={componentRef}>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50]">
        View: Bid Evaluation Summary Report
      </p>
      <div className="grid gap-4 grid-cols-2 mt-6 w-[95%] ml-6">
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Name of procurement entity:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] bg-white"
            value={nameOfProcurementEntity}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Name of Procurement
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={nameOfProcurement}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Source of financial resources:
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={sourceFinacialResources}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Pre-bid estimated amount <br /> of procurement contract :
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={prebidEstimatedAmount}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Method of procurement <br /> (ICB/NCB/LIB/LNB etc.):
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={methodOfProcurement}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Number of bidder <br /> documents issued:
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={numbersOfbidersDocument}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Number of bids received
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={numberOfBidsReceived}
            disabled
          />
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Number of bids determined as
            <br /> substantial responsive bids
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%] bg-white"
            value={numberOfbidsDeterminded}
            disabled
          />
        </div>
      </div>

      <div>
        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Any common reason/s if any leading to determination as
            non-responsive bids:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] mt-1 bg-white"
            value={anyCommonReasons}
            disabled
          />
        </div>

        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Whether the evaluated lowest bidder is recommended for award of the
            contract:
          </p>
          <div className="mt-2 text-[22px] text-[#8691AB] font-bold">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio h-6 w-6 text-blue-500"
                name="recommendation"
                value="Yes"
                checked={bidderRecommendation === "Yes"}
                disabled
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio text-blue-500 h-6 w-6"
                name="recommendation"
                value="No"
                checked={bidderRecommendation === "No"}
                disabled
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            If the answer to above is no give the reason/s:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] mt-1 bg-white"
            value={ifTheAnswertoAbove}
            disabled
          />
        </div>

        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            What is the recommended offer price:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] mt-1 bg-white"
            value={recommendedPrice}
            disabled
          />
        </div>

        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            What is the recommended bidder’s bid price at bid opening:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] mt-1 bg-white"
            value={recommendedBidderPrice}
            disabled
          />
        </div>

        <div className="ml-6 mt-6">
          <p className="text-[22px] text-[#8691AB] font-bold">
            If there is a difference between (12) and (13) above, mention the
            reason/s:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[50%] mt-1 bg-white"
            value={deferentBetweenReason}
            disabled
          />
        </div>
      </div>
      <div className="mb-6 mt-6 ml-6">
        <button
          className="bg-[#797C80] text-[20px] font-bold text-white rounded-[33px] w-40 h-8 print:hidden"
          onClick={pdfView}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
};

export default Bid_Evaluation_Summary_Report_view;
