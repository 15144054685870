/** @format */

import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateBranches() {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const selectedRecord = location.state?.selectedRecord || {};
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [resultShow, setResultShow] = useState(false);

  const [result, setResult] = useState([]);

  const [data, setData] = useState({
    branch_id: selectedRecord.branch_id || "",
    branch_name: selectedRecord.branch_name || "",
    branch_address: selectedRecord.branch_address || "",
    branch_description: selectedRecord.branch_description || "",
    branch_update_date: selectedRecord.branch_update_date || formattedDate,
    branch_update_user_id: selectedRecord.branch_update_user_id || userId,
  });

  const UpdateHandler = async () => {
    if (data.branch_id !== "") {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/branch/update/Branch/${data.branch_id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              branch_name: data.branch_name,
              branch_address: data.branch_address,
              branch_description: data.branch_description,
              branch_update_date: formattedDate,
              branch_update_user_id: userId, // Current date and time
            }),
          }
        );

        if (res.ok) {
          alert("Branch Updated Successfully");
          navigate("/branches/view");
        } else {
          const errorData = await res.json();
          if (res.status === 409) alert("Store Already Exists");
          else if (res.status === 400) alert("Store Not Updated");
          else if (res.status === 500) alert("Internal Server Error");
          else if (res.status === 404) alert("Store Not Found");
          else if (res.status === 403) alert("Forbidden");
          else if (res.status === 401) alert("Unauthorized");
          else alert(`Error: ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error updating branch:", error);
        alert("An error occurred while updating the branch.");
      }
    }
  };

  const DeleteHandler = async () => {
    alert("are you sure!");
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/branch/delete/Branch/${data.branch_id}`
      );
      // console.log(res.data);
      navigate("/branches/view");
    } catch (error) {
      if (error.response.status === 409) {
        alert("Branch Already Exist");
      } else if (error.response.status === 400) {
        alert("Branch Not Deleted");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Branch Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const CancelHandler = async () => {
    navigate(-1);
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1 mt-5"> Update Branches</p>

      <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center  mt-5">
        <p className="header-1">Update Details</p>
        <div className="flex items-center justify-between flex-wrap w-full gap-y-10 py-10">
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch ID :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={data.branch_id}
              onChange={(e) => setData({ ...data, branch_id: e.target.value })}
              disabled
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Name :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={data.branch_name}
              onChange={(e) => {
                setData({ ...data, branch_name: e.target.value });
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Address :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={data.branch_address}
              onChange={(e) => {
                setData({ ...data, branch_address: e.target.value });
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Description :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={data.branch_description}
              onChange={(e) => {
                setData({ ...data, branch_description: e.target.value });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-transparent border border-black rounded-[23px] px-4"
          onClick={CancelHandler}
        >
          <p className="p2 text-black">Cancel</p>
        </button>
        {hasPermission(1510) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
            onClick={DeleteHandler}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1500) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={UpdateHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>
    </div>
  );
}
